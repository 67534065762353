import * as React from "react";

import "moment-timezone";
import moment from "moment";

import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

import Stack from '@mui/material/Stack';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import TablePagination from "@mui/material/TablePagination";

import fallback from "../../../images/fallback.png";

function GetRows({ alerts, cameras }) {
    let rows = [];

    for (const a in alerts) {
        if (alerts[a].item_type === "camera") {
            const camera = cameras.find(row => row["id"] === alerts[a].item_id);
            if (camera) {
                rows = rows.concat([
                    {
                        "id": alerts[a].item_id,
                        "alias_id": camera.alias_id ? camera.alias_id : '',
                        "name": camera.meta.name,
                        "sla": camera.sla,
                        "status": alerts[a].status,
                        "status_message": alerts[a].status_message,
                        "created_at": alerts[a].created_at,
                    }
                ]);
            }
        }
    }

    return rows;
}


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Name",
    },
    {
        id: "alias_id",
        numeric: false,
        disablePadding: false,
        label: "Alias ID",
    },
    {
        id: "sla",
        numeric: false,
        disablePadding: false,
        label: "SLA",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
    },
    {
        id: "created_at",
        numeric: true,
        disablePadding: false,
        label: "Erstellt",
    },
];


function EnhancedTableHead(props) {
    const {
        order,
        orderBy,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function CameraImage({ id, playerConfig }) {
    const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

    React.useEffect(() => {
        const img = new Image();
        img.src = `${playerConfig.url}${id}/live/main_240.jpg`;
        img.onload = () => {
            setBackgroundImage(`url(${img.src})`);
        };
    }, []);

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundImage: backgroundImage,
                backgroundSize: "cover",
                padding: 0,
                height: 30,
                minWidth: 55,
                borderRadius: 1,
                border: "1px solid #f1f1f1",
            }}
        />
    );
}


EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function EnhancedTable(props) {
    const { alerts, cameras, playerConfig, camera_id, navigate, rootPath, searchText, slaFilterStates } = props;
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("created_at");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const rows = GetRows({ alerts, cameras });

    const isSelected = (id) => {
        return camera_id === id;
    };
    const handleClick = (event, row) => {
        navigate(`${rootPath}/${row.id}`);
    };

    function transAlertStatus(status) {
        if (status === "open") {
            return "Offen";
        } else if (status === "closed") {
            return "Geschlossen";
        } else if (status === "in_progress") {
            return "In Arbeit";
        } else if (status === "waiting") {
            return "Wartend";
        } else {
            return status;
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const slaFilter = (sla) => {
        if (sla === "0" && slaFilterStates.sla0) {
            return true;
        } else if (sla === "8" && slaFilterStates.sla8) {
            return true;
        } else if (sla === "2" && slaFilterStates.sla2) {
            return true;
        } else if (!sla && slaFilterStates.slaNone) {
            return true;
        } else {
            return false;;
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const filteredRows = React.useMemo(() => {
        const lowerSearchText = searchText.toLowerCase();
        let count = 0;
    
        const filtered = rows.filter((row) => {
            const slaMatch = slaFilter(row.sla);
            const searchMatch = row.alias_id.toLowerCase().includes(lowerSearchText) || row.name.toString().toLowerCase().includes(lowerSearchText);
    
            if (slaMatch) {
                count += 1;
            }
    
            return slaMatch && searchMatch;
        });
    
        if (page !== 0) setPage(0);
    
        return filtered;
    }, [rows, slaFilterStates, searchText, page]);

    const visibleRows = React.useMemo(
        () =>
            stableSort(filteredRows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [filteredRows, order, orderBy, page, rowsPerPage],
    );   

    return (
        <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={"medium"}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                if (row) {
                                    return (
                                        <TableRow
                                            hover
                                            role="row"
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{ cursor: "pointer" }}
                                            onClick={(event) =>
                                                handleClick(event, row)
                                            }
                                            selected={isSelected(row.id)}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                padding="normal"
                                            >
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <CameraImage
                                                        id={row.id}
                                                        playerConfig={playerConfig}
                                                    />
                                                    <span>{row.name}</span>
                                                </Stack>
                                            </TableCell>
                                            <TableCell>
                                                {row.alias_id}
                                            </TableCell>
                                            <TableCell>
                                                {row.sla && (
                                                    <Chip
                                                        size="small"
                                                        //icon={<SupportIcon style={{ color: "#fff" }} />}
                                                        style={{
                                                            color: "#fff",
                                                            backgroundColor: "#0085E9",
                                                            padding: 0,
                                                        }}
                                                        label={`SLA ${row.sla === "0" ? "" : row.sla}`}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <Chip
                                                    size="small"
                                                    //icon={<SupportIcon style={{ color: "#fff" }} />}
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: "#0085e9",
                                                        padding: 0,
                                                    }}
                                                    label={transAlertStatus(row.status ? row.status : "open")}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                {moment
                                                    .utc(row.created_at)
                                                    .local()
                                                    .format("DD.MM.YYYY HH:mm:ss")
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    labelRowsPerPage="Meldungen pro Seite"
                    rowsPerPageOptions={[50, 100, 250]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}
