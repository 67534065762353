import * as React from "react";
import PropTypes from "prop-types";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"; // Verwende den Adapter für moment.js
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';

import moment from "moment";
import "moment/locale/de";


export function DateSelect(props) {
    const { 
        label, 
        name = null,
        value, 
        setValue, 
        views = ['year', 'day', 'hours', 'minutes'],
        color = "primary", 
        minDateTime = moment().seconds(0).subtract(14, "days"), 
        maxDateTime = moment().seconds(0),
    } = props;

    const handleChange = (value) => {
        const date = new Date(value);
        if (!views.includes("minutes")) {
            date.setMinutes(0, 0, 0);
        }
        if (name) {
            const event = { target: {name: name, value: date, type: "date"} };
            setValue(event);
        } else {
            setValue(date);
        } 
    };

    // Erstelle eine moment.js-Zeitzoneinstanz für die lokale Zeitzone
    const localTimeZone = moment.tz.guess(); // Ermittelt die lokale Zeitzone

    // Konvertiere den bestehenden 'value' in die lokale Zeitzone
    const valueInLocalTimeZone = value ? moment.tz(value, localTimeZone) : null;

    moment.locale("de");
    const momentConfig = { moment };

    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateFormats={momentConfig}
            adapterLocale="de"
        >
            <DateTimePicker
                label={label}
                name={name ? name : label}
                size="small"
                color={color}
                variant="outlined"
                selectedSections={"day"}
                minutesStep={!views.includes("minutes") ? 0 : 1}
                hoursStep={!views.includes("hours") ? 0 : 1}
                views={views}
                displayWeekNumber
                timezone={localTimeZone}
                value={valueInLocalTimeZone}
                maxDateTime={maxDateTime}
                minDateTime={minDateTime}
                onChange={handleChange}
                slotProps={{ 
                    desktopPaper: {
                        sx: {
                            "& .MuiMultiSectionDigitalClockSection-item:hover": {
                                backgroundColor: "rgba(0,132,232,.9)",
                            },
                            "& .MuiMultiSectionDigitalClockSection-item.Mui-selected": {
                                backgroundColor: "rgba(0,132,232,.9)",
                                color: "#fff",
                            },
                        },
                    },
                    textField: {
                        InputLabelProps: {
                            sx: {
                                backgroundColor: '#fff',
                                paddingLeft: '5px',
                                paddingRight: '5px',
                            }
                        },
                        inputProps: {
                            name: name ? name : label,
                            sx: { 
                                padding: "10px 14px",
                            }
                        },
                        sx: {
                            borderRadius: 1, 
                            border: color === "secondary" ? "1px solid rgba(0,0,0,.2)" : null,
                        }
                    }
                }}
            />
        </LocalizationProvider>
    );
}

DateSelect.propTypes = {
    label : PropTypes.string.isRequired,
    name: PropTypes.string,
    value : PropTypes.instanceOf(moment),
    setValue : PropTypes.func.isRequired,
    views : PropTypes.array,
    color: PropTypes.string,
    minDateTime: PropTypes.instanceOf(moment),
    maxDateTime: PropTypes.instanceOf(moment),
};

export default DateSelect;