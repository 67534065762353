import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import ErrorIcon from '@mui/icons-material/Error';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


function AlertBoxDetail({ 
    dialogOpen = true,
    setDialogOpen = () => {},
    mousePosition = { x: 0, y: 0 },
    selectedRow = {},
}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    React.useEffect(() => {
        if (selectedRow) {
            setAnchorEl(mousePosition ? document.elementFromPoint(mousePosition.x, mousePosition.y) : null);
        }
    // eslint-disable-next-line
    }, []);

    const handleCloseMenu = () => {
        setDialogOpen(false);
    };

    const camera = selectedRow ? selectedRow.row.camera : null;
    const alert = selectedRow ? selectedRow.row.alert : null;

    const error_keywords = {
        "failed_to_resolve_hostname": "Die Kameraadresse konnte nicht aufgelöst werden. Bitte überprüfen Sie den hinterlegten Domainnamen.",
        "host_is_unreachable": "Die Kameraadresse ist nicht erreichbar. Bitte überprüfen Sie Ihre Netzwerkverbindung.",
        "forbidden": "Zugriff verweigert. Bitte überprüfen Sie die Zugangsdaten der Kamera oder ihres Streaming-Servers.",
        "unauthorized": "Nicht autorisiert. Bitte überprüfen Sie die Zugangsdaten der Kamera.",
        "invalid_data_found": "Die Kamera produziert ungültige Videodaten. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.",
        "io_error": "Der RTMP Stream konnte nicht geöffnet werden. Bitte überprüfen Sie, ob ihr RTMP-Stream-Sendeverfahren korrekt konfiguriert ist.",
        "not_found": "Der Kamerastream konnte nicht geöffnet werden. Bitte überprüfen Sie die Freigabe auf ihrem Streaming-Server.",
        "could_not_open": "Der Kamerastream konnte nicht geöffnet werden. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.",
        "could_not_find_codec_parameters_for_stream": "Die Kamera liefert unvollständige Videodaten. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.",
        "got_error_from_av_read_frame": "Der Kamerastream konnte nicht geöffnet werden. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.",
        "immidiate_exit_requested": "Der Kamerastream konnte nicht geöffnet werden. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.",
        "update_your_ffmpeg_version": "Die Kamera produziert ungültige Daten. Bitte wenden Sie sich an den Support.",
        "option_playout_uuid_not_found": "Es wurde eine Unstimmigkeit in der Konfiguration festgestellt. Bitte wenden Sie sich an den Support.",
        "got_error": "Die Kamera produziert einen unbekannten Fehler. Bitte wenden Sie sich an den Support.",
        "not_connected": "Die Kamera ist nicht verbunden. Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.",
        "connected": "Die Kamera ist verbunden.",
        "fps_failed": "Die Kamera liefert unter 15 Bilder pro Sekunde (FPS). Bitte überprüfen Sie die Kameraeinstellungen und die Netzwerkverbindung.",
        "stopped": "Der Kamerastream wurde im Kamera-Management gestoppt.",
    };

    return (
        <Menu
            open={Boolean(dialogOpen && selectedRow)}
            onClose={handleCloseMenu}
            anchorEl={anchorEl}
        >
            <MenuList dense sx={{
                paddingTop: 0
            }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    padding={"5px 15px 5px 15px"}
                    sx={{
                        backgroundColor: "rgba(0,0,0,.03)",
                        height: 42
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={.5}
                    >
                        {camera.status === "healthy" && (
                            <>
                                <CheckCircleIcon style={{ color: "#39B54A" }} />
                                <Typography variant="h6">
                                    Verbunden
                                </Typography>
                            </>
                        )}
                        {camera.status === "unhealthy" && (
                            <>
                                <ErrorIcon style={{ color: "rgba(193,39,45,.9)" }} />
                                <Typography variant="h6">
                                    Verbindungsfehler
                                </Typography>
                            </>
                        )}
                        {camera.status === "unknown" && (
                            <>
                                <CancelIcon style={{ color: "rgba(193,39,45,.9)" }} />
                                <Typography variant="h6">
                                    Unbekannter Zustand
                                </Typography>
                            </>
                        )}
                    </Stack>
                </Stack>
                <Divider style={{ borderWidth: 1, marginTop: 0, marginBottom: 0 }} />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0}
                    padding={"5px 15px 2px 15px"}
                    maxWidth={315}
                    sx={{
                        backgroundColor: "rgba(0,0,0,.02)",
                    }}
                >
                    <Typography variant="h6">Fehlermeldung:</Typography>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        style={{ 
                            wordWrap: "break-word",
                            marginBottom: 8,
                        }}
                    >
                        {alert.messages &&
                            alert.messages.length > 0 &&
                            alert.messages.map((message) => (
                                <Typography fontSize={15} key={message}>
                                    {message.split(",")[0] in error_keywords ? error_keywords[message.split(",")[0]] : message}
                                </Typography>
                            ))}
                    </Stack>
                </Stack>
                {alert.status_message && (
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}
                        padding={"10px 15px 10px 15px"}
                        maxWidth={315}
                        sx={{
                            backgroundColor: "rgba(193,39,45,.2)",
                        }}
                    >
                        <Typography variant="h6">Hinweis:</Typography>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={1}
                            style={{ 
                                wordWrap: "break-word",
                            }}
                        >
                            <Typography fontSize={15} key={alert.status_message}>
                                {alert.status_message}
                            </Typography>
                        </Stack>
                    </Stack>
                )}
                <Divider sx={{marginTop: 0}}/>
                <MenuItem
                    component={Link}
                    to={`/live/camera/${camera.id}`}
                >
                    <ListItemText>Zur Kamera wechseln</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    component={Link}
                    to={`/analytics/camera?camera_id=${camera.id}`}
                >
                    <ListItemText>Analytics aufrufen</ListItemText>
                </MenuItem>
                {(camera.sla === "2" || camera.sla === "8") && (
                    <>
                        <Divider />
                        <MenuItem
                            component={Link}
                            to={`/video/camera?camera_id=${camera.id}&latest=50`}
                        >
                            <ListItemText>Videos anzeigen</ListItemText>
                        </MenuItem>
                    </>
                )}
            </MenuList>
        </Menu>
    );
}

AlertBoxDetail.propTypes = {
    dialogOpen: PropTypes.bool,
    setDialogOpen: PropTypes.func,
    mousePosition: PropTypes.object,
    selectedRow: PropTypes.object,
};

export default AlertBoxDetail;