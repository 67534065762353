import React, { useState } from 'react';
import PropTypes from "prop-types";

import Check from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FilterListIcon from '@mui/icons-material/FilterList'; 
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';


function AlertBoxSlaFilter(props) {
    const { 
        slaFilterStates = {
            sla2: true,
            sla8: true,
            sla0: true,
            slaNone: true
        },
        setSlaFilterStates= () => {},
        outlined = false,
        iconButton = true,
    } = props;

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const handleMenuItemClick = (name) => {
        setSlaFilterStates({ ...slaFilterStates, [name]: !slaFilterStates[name] });
    };
    const handleOpenMenu = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setMenuAnchorEl(null);
    };

    const slaName = (name) => {
        switch (name) {
            case 'sla2':
                return 'SLA 2';
            case 'sla8':
                return 'SLA 8';
            case 'sla0':
                return 'SLA';
            case 'slaNone':
                return 'Ohne';
            default:
                return name;
        }
    }

    if (iconButton) {
        return (
            <>
                <IconButton 
                    onClick={handleOpenMenu} 
                    aria-label="Filter"
                    sx={{ 
                        padding: 0,
                        height: 41,
                        width: 45,
                        minWidth: 40,
                        boxShadow: outlined ? 1 : 0, 
                        borderRadius: 1, 
                        background: "#fff", 
                        border: outlined ? "1px solid rgba(0,0,0,.2)" : "none"
                    }}
                >
                    <FilterListIcon />
                </IconButton>
                <Menu
                    anchorEl={menuAnchorEl}
                    open={Boolean(menuAnchorEl)}
                    onClose={handleCloseMenu}
                >
                    <ListItem style={{ pointerEvents: 'none', backgroundColor: "rgba(0,0,0,.03)" }}>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <FilterListIcon />
                            <Typography variant="h6">
                                SLA Filter
                            </Typography>
                        </Stack>
                    </ListItem>
                    <Divider style={{ borderWidth: 1, marginTop: 0, marginBottom: 0 }} />
                    {Object.entries(slaFilterStates).map(([name, checked]) => (
                        <MenuItem
                            key={name}
                            disableRipple
                            onClick={() => handleMenuItemClick(name)}
                            selected={checked}
                        >
                            <ListItemIcon>
                                {checked && <Check style={{color: "#fff"}} />}
                            </ListItemIcon>
                            {slaName(name)}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    } else {
        const slaOptions = Object.keys(slaFilterStates);
        return (
            <FormControl fullWidth color="primary" size="small" variant="outlined">
                <InputLabel id="sla-filter-label">SLA</InputLabel>
                <Select
                    labelId="sla-filter-label"
                    multiple
                    value={slaOptions.filter(option => slaFilterStates[option])}
                    onChange={(event) => {
                        const value = event.target.value;
                        const newSlaFilterStates = slaOptions.reduce((acc, option) => {
                            acc[option] = value.includes(option);
                            return acc;
                        }, {});
                        setSlaFilterStates(newSlaFilterStates);
                    }}
                    renderValue={(selected) => selected.map(slaName).join(', ')}
                    size="small"
                    fullWidth
                >
                    {slaOptions.map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={slaFilterStates[name]} 
                                sx={{
                                    color: 'inherit',
                                    '&.Mui-checked': {
                                        color: 'inherit',
                                    },
                                    '&.MuiCheckbox-indeterminate': {
                                        color: 'inherit',
                                    },
                                }}  
                            />
                            {slaName(name)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}

AlertBoxSlaFilter.propTypes = {
    slaFilterStates: PropTypes.object,
    setSlaFilterStates: PropTypes.func,
};

export default AlertBoxSlaFilter;