import * as React from "react";

import PropTypes from "prop-types";

import Page from "../../components/Page";

import AdminProtocol from "./AdminProtocol";

import { useApp } from "../../context/App";

function Admin(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PERMISSION CHECK
    const adminRoles = ["admin"];

    React.useEffect(() => {
        (async () => {
            setSelectedPage("admin_protocol");
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    }, [setSelectedPage, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    return (
        <Page
            drawerWidth={550}
            pageTitel="Protokoll"
			breadcrumbs={[
                {title: 'Admin', link: '#'},
                {title: 'Protokoll', link: rootPath}
            ]}
        >
            {hasPermission(adminRoles) && (
                <AdminProtocol />
            )}
        </Page>
    );
}

Admin.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

export default Admin;
