import * as React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import "moment-timezone";
import moment from "moment";
import "moment/locale/de";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import EventNoteIcon from '@mui/icons-material/EventNote';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// PAGE
import Page from "../../components/Page";
import DownloadDialog from "../../components/DownloadDialog";
import SearchField from "../../components/SearchField";

// PAGE SIDEBAR
import VideoSidebar from "./VideoSidebar";
import VideoFilter from "./VideoFilter";

import { useApp } from "../../context/App";

import fallback from "../../images/fallback.png";


const ResolutionIcons = ({ video_sources }) => {
    if (video_sources.length === 0) {
        return null;
    }
    // [
    //     {
    //         "format": "hls",
    //         "codec": "h264, aac",
    //         "resolution": "1280x720",
    //         "bitrate": 530.22,
    //         "fps": 24.87
    //     }
    // ]
    const resolutions = video_sources.map((video_source) => video_source.video_height);
    resolutions.sort((a, b) => a - b);

    return (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} paddingTop={0.8} paddingLeft={1.2} width={'100%'}>
            {resolutions.map((resolution, index) => {
                return (
                    <Chip
                        key={index}
                        size="small"
                        label={resolution}
                        sx={{
                            color: "#fff",
                            backgroundColor: "#0085E9",
                            p: ".5em .1em",
                        }}
                    />
                );
            })}
        </Stack>
    );
};

const DownloadIcon = ({ video, downloadList }) => {
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
        >
            {downloadList.find(
                (vendor) => vendor["id"] === video.id,
            ) && (
                <Chip
                    size="small"
                    sx={{
                        color: "#fff",
                        backgroundColor: "#0085E9",
                        p: ".5em .1em",
                        "& .MuiChip-icon": {
                            color: "#fff",
                            marginRight: "-12px",
                        },
                    }}
                    icon={<PlaylistRemoveIcon />}
                />
            )}
        </Stack>
    );
};


const DurationIcon = ({ video_duration_seconds }) => {
    return (
        <Stack
            direction="row"
            justifyContent="flex-end" 
            alignItems="flex-end" 
            spacing={2}
        >
            <Chip
                size="small"
                sx={{
                    color: "#fff",
                    backgroundColor: "#0085E9",
                    p: ".5em .1em",
                }}
                label={
                    video_duration_seconds +
                    " Sek"
                }
            />
        </Stack>
    );
};

function VideoItem(props) {
    const { video, video_id, playerConfig, handleSelectVideo, downloadList } = props;
    const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

    React.useEffect(() => {
        const img = new Image();
        img.src = `${video.video_sources[0].video_poster.replace(
            ".jpg",
            "_360.jpg",
        )}?token=${playerConfig.token}`;
        img.onload = () => {
            setBackgroundImage(`url(${img.src})`);
        };
    }, []);

    return (
        <Grid
            item
            key={video.id}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            xxl={3}
            alignItems="flex-start"
            style={{ cursor: "pointer" }}
            onClick={handleSelectVideo(video)}
        >
            <Paper
				elevation={0}
				sx={{
					position: 'relative',
					backgroundImage: backgroundImage,
					backgroundSize: 'cover',
					backgroundColor: 'transparent',
					padding: 0,
					paddingTop: '56.25%',
					height: 0,
					width: 'auto',
					borderRadius: '4px 4px 0px 0px',
				}}
			>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					width={'100%'}
					height={'100%'}
					sx={{ 
						backgroundColor: (video_id && video.id === video_id) ? "rgba(0,133,233,.5)" : "transparent",
						position: 'absolute', 
						top: 0, 
						left: 0, 
						bottom: 0, 
						right: 0,
						borderRadius: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={0}
						width={'100%'}
						marginTop={0.8} 
						marginBottom={1.4} 
						sx={{
							background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
							//background: 'linear-gradient(to bottom, rgba(193,39,45,.9), rgba(193,39,45,0))',
							// 
							width: '100%',
							borderRadius: '4px 4px 0px 0px',
							height: '35%',
							padding: '0.15em 0.8em',
						}}
					>
						<Stack 
							direction="column" 
							width={'100%'}
						>
							<Typography
								variant="h5"
								//marginBottom={0}
								sx={{
									fontSize: '1rem',
									fontWeight: 500,
									color: '#ffffff',
									lineHeight: '1.1rem',
									marginBottom: '0.3em',
									marginTop: '0.3em',
								}}
							>
								{video.camera_meta.name}
							</Typography>
							<Typography 
								variant="body2" 
								color="text.secondary"
								sx={{
									color: '#ffffff',
									marginTop: '-0.4em',
									fontWeight: 400,
								}}
							>
								{video.camera_meta.country}
							</Typography>
                            
						</Stack>
                        
					</Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))',
                            width: '100%',
                            borderRadius: 1,
                            height: '25%',
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            paddingBottom: 1.2,
                            paddingLeft: .2,
                            paddingRight: 1.5,
                        }}
                    >
                        <Stack 
                            direction="row" 
                            width={'auto%'}
                        >
                            <ResolutionIcons video_sources={video.video_sources} />
                        </Stack>
                        <Stack
                            direction="row" 
                            width={'auto%'}
                            spacing={1}
                        >
                            <DownloadIcon video={video} downloadList={downloadList} />
                            <DurationIcon video_duration_seconds={video.video_duration_seconds} />
                        </Stack>
                        </Stack>
				</Stack>
			</Paper>
            <Stack
                direction="row"
                spacing={0}
                width={'100%'}
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "white",
                    width: '100%',
                    borderRadius: '0px 0px 4px 4px',
                    borderLeft: '1px solid rgba(0,0,0,.1)',
                    borderRight: '1px solid rgba(0,0,0,.1)',
                    borderBottom: '1px solid rgba(0,0,0,.1)',
                    height: '30px',
                    padding: '13px 10px 10px 10px',
                }}
			>
                <EventNoteIcon style={{ fontSize: "16px", color: "rgba(0,0,0,1)", marginTop: -5, marginRight: 5 }} />
                <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{
                        color: '#000',
                        marginTop: '-0.4em',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {moment
                        .utc(video.created_at)
                        .local()
                        .format("DD.MM.YYYY HH:mm:ss")}
                </Typography>
            </Stack>
        </Grid>
    );
}



function VideoList(props) {
    const { videos, video_id, page, pageSize, playerConfig, handleSelectVideo, downloadList } = props;
    const paginateArray = (array, pageNumber, pageSize) => {
        const page = array.slice(
            (pageNumber - 1) * pageSize,
            pageNumber * pageSize,
        );
        return page;
    };

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1.5}
            marginTop={"-7px"}
        >
            {paginateArray(videos, page, pageSize).map((video) => {
                return (
                    <VideoItem key={video.id} video={video} video_id={video_id} playerConfig={playerConfig} handleSelectVideo={handleSelectVideo} downloadList={downloadList}/>
                );
            })}
        </Grid>
    );
}

VideoList.propTypes = {
    videos: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    playerConfig: PropTypes.object.isRequired,
    downloadList: PropTypes.array.isRequired,
    handleSelectVideo: PropTypes.func.isRequired,
};

VideoList.defaultProps = {};

function Video(props) {
    const { rootPath } = props;
    const { client, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        setOpenSidebar(!openSidebar);
        navigate(rootPath);
    };

    // PLAYER OBJECT
    const [playerConfig, setPlayerConfig] = React.useState(false);
    const getPlayerConfig = async () => {
        if (!playerConfig) {
            const data = await client.PlayerConfig();
            setPlayerConfig(data);
        }
        return playerConfig;
    };

    // VIDEO OBJECT
    const navigate = useNavigate();
    const { video_id } = useParams();
    const [videos, setVideos] = React.useState(false);
    const [video, setVideo] = React.useState(false);
    const handleSelectVideo = React.useCallback(
        (video) => () => {
            setVideo(video);
            navigate(`${rootPath}/${video.id}`);
            setOpenSidebar(true);
        },
        // eslint-disable-next-line
        [],
    );

    // camera search
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const param_camera_id = params.get('camera_id');

    // PAGINATION
    const [limit, setLimit] = React.useState(500);
    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
    const [pageSize, setPageSize] = React.useState(50);
    const handlePageSize = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    // META SEARCH, SORT AND DATE
    const [searchText, setSearchText] = React.useState(null);
    const [sort, setSort] = React.useState("created_at");
    // eslint-disable-next-line
    const handleSort = (event) => {
        setSort(event.target.value);
    };
    const [sortDirection, setSortDirection] = React.useState("desc");
    // eslint-disable-next-line
    const handleSortDirection = (event) => {
        setSortDirection(event.target.value);
    };
    const refreshFromDate = () => {
        return moment().subtract(8, "hours");
    };
    const [fromDate, setFromDate] = React.useState(refreshFromDate);
    const refreshToDate = () => {
        return moment();
    };
    const [toDate, setToDate] = React.useState(refreshToDate);
    
    function getDate(date) {
        const momentDate = moment.utc(date);
        return momentDate.format("YYYY-MM-DDTHH:mm:ss");
    }

    const [autoCompleteOptions, setAutoCompleteOptions] = React.useState(null);
    const getAutoCompleteOptions = async () => {
        const newOptions = [];

        const addKey = (name) => {
            const value = String(name);
            if (!newOptions.includes(value)) {
                newOptions.push(value);
            }
        };

        if (searchText && searchText.length > 0) {
            const metaSearchLower = searchText.toLowerCase(); // searchText in Kleinbuchstaben umwandeln

            for (const video of videos) {
                if (video.tags) {
                    for (const tag of video.tags) {
                        if (tag.toLowerCase().includes(metaSearchLower)) {
                            addKey(tag);
                        }
                    }
                }
                if (video.camera_meta) {
                    const { name, city, region, country, tags } =
                        video.camera_meta;

                    if (name && name.toLowerCase().includes(metaSearchLower)) {
                        addKey(name);
                    }
                    if (city && city.toLowerCase().includes(metaSearchLower)) {
                        addKey(city);
                    }
                    if (
                        region &&
                        region.toLowerCase().includes(metaSearchLower)
                    ) {
                        addKey(region);
                    }
                    if (
                        country &&
                        country.toLowerCase().includes(metaSearchLower)
                    ) {
                        addKey(country);
                    }
                    if (tags) {
                        for (const tag of tags) {
                            if (tag.toLowerCase().includes(metaSearchLower)) {
                                addKey(tag);
                            }
                        }
                    }
                }
            }
        }

        const uniqueOptions = newOptions.filter(
            (value, index, array) => array.indexOf(value) === index,
        );
        const formattedOptions = uniqueOptions.map((option) => ({
            title: option,
        }));

        setAutoCompleteOptions(formattedOptions);
    };

    // DOWNLOAD LIST
    const [downloadList, setDownloadList] = React.useState([]);
    const handleItemOnDownloadList = (video) => {
        if (downloadList.find((file) => file["id"] === video.id)) {
            setDownloadList(
                downloadList.filter((file) => file["id"] !== video.id),
            );
        } else {
            setDownloadList(downloadList.concat([video]));
        }
    };
    const [downloadDialogOpen, setDownloadDialogOpen] = React.useState(false);
    const handleClickDialogOpen = () => {
        setDownloadDialogOpen(true);
    };
    const handleDownloadDialogClose = () => {
        setDownloadDialogOpen(false);
    };

    const filterVideos = (videos) => {
        return(
            videos.sort((a, b) => {
                if (sort === "created_at") {
                    const dateA = new Date(a[sort]);
                    const dateB = new Date(b[sort]);
                    return sortDirection === "asc"
                        ? dateA - dateB
                        : dateB - dateA;
                } else if (sort.startsWith("camera_meta.")) {
                    const subKey = sort.replace("camera_meta.", "");
                    return sortDirection === "asc"
                        ? a.camera_meta[subKey].localeCompare(
                              b.camera_meta[subKey],
                          )
                        : b.camera_meta[subKey].localeCompare(
                              a.camera_meta[subKey],
                          );
                } else {
                    return 0;
                }
            })
        );
    };

    const filteredVideos = React.useMemo(() => {
        if (!Array.isArray(videos)) {
            return [];
        }
        setPage(1);
        return filterVideos(videos);
        // eslint-disable-next-line
    }, [videos, sort, sortDirection]);

    const getVideos = async ({refreshDate = true} = {}) => {
        const from_date = refreshDate ? getDate(refreshFromDate()) : getDate(fromDate);
        const to_date = refreshDate ? getDate(refreshToDate()) : getDate(toDate);
        const params = {
            camera_id: param_camera_id,
            meta_search: searchText,
            from_date: !param_camera_id ? from_date : null,
            to_date: !param_camera_id ? to_date : null,
            limit: limit,
        };
        const data = await client.GetCustomerVideo(params);
        setVideos(data);
        await getAutoCompleteOptions();
        if (video_id && !video) {
            for (const v in data) {
                if (data[v].id === video_id) {
                    setVideo(data[v]);
                    setOpenSidebar(true);
                }
            }
        }
        setPage(1);
    };

    React.useEffect(() => {
        (async () => {
            setSelectedPage("video_camera");
            await getPlayerConfig();
            await getVideos();
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
        // eslint-disable-next-line
    }, [client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (videos) {
            getVideos();
        }
        // eslint-disable-next-line
    }, [limit, searchText]);

    React.useEffect(() => {
        if (videos) {
            getVideos({refreshDate: false});
        }
        // eslint-disable-next-line
    }, [fromDate, toDate]);

    React.useEffect(() => {
        if (videos && video_id) {
            if (!video || video.id !== video_id) {
                const data = videos.find((video) => video.id === video_id);
                if (!data) {
                    navigate(rootPath);
                } else {
                    setVideo(data);
                    setOpenSidebar(true);
                }
            }
        } else {
            setVideo(null);
            setOpenSidebar(false);
        }
    }, [videos, video_id]);

    const breadcrumbs = () => {
		if (video) {
			return [
				{title: 'Video', link: '#'},
                {title: 'Kamera', link: rootPath},
				{title: video.id, link: `${rootPath}/${video.id}`}
			]
		} else {
			return [
				{title: 'Video', link: '#'},
                {title: 'Kamera', link: rootPath},
			]
		};
	}

    return (
        <Page
            pageTitel="Kamera Aufnahmen"
            breadcrumbs={breadcrumbs ? breadcrumbs() : []}
            headerContentRight={
                <React.Fragment>
                    <SearchField 
						outlined={true}
						searchText={searchText}
						setSearchText={setSearchText}
						autoComplete={true}
						autoCompleteOptions={autoCompleteOptions}
					/>
                    <VideoFilter
                        sort={sort}
                        handleSort={handleSort}
                        sortDirection={sortDirection}
                        handleSortDirection={handleSortDirection}
                        fromDate={fromDate}
                        setFromDate={setFromDate}
                        toDate={toDate}
                        setToDate={setToDate}
                        limit={limit}
                        setLimit={setLimit}
                    />
                    {downloadList.length > 0 && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClickDialogOpen}
                            startIcon={<FileDownloadIcon />}
                            sx={{ 
                                m: '.5em 1em 0.5em 0em',
                                padding: 0,
                                height: 40,
                                //minWidth: 40,
                                boxShadow: 1, 
                                borderRadius: 1, 
                                background: "#fff", 
                                border: "1px solid rgba(0,0,0,.2)"
                            }}
                        >
                            {downloadList.length}
                        </Button>
                    )}
                </React.Fragment>
            }
            drawerWidth={550}
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                video ? (
                    <VideoSidebar
                        key={video.id}
                        drawerWidth={550}
                        video={video}
                        playerConfig={playerConfig}
                        handleItemOnDownloadList={handleItemOnDownloadList}
                        downloadList={downloadList}
                    />
                ) : null
            }
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
                marginTop={1.5}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    width={"100%"}
                >
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={0}
                        paddingBottom={5}
                    >
                        {videos && (
                            <VideoList
                                videos={filteredVideos}
                                video_id={video_id}
                                page={page}
                                pageSize={pageSize}
                                handleSelectVideo={handleSelectVideo}
                                playerConfig={playerConfig}
                                downloadList={downloadList}
                            />
                        )}
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        marginBottom={2}
                    >
                        <Paper
                            elevation={2}
                            sx={{
                                borderRadius: 2,
                                width: "auto",
                                p: "4px 8px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Pagination
                                count={
                                    filteredVideos
                                        ? Math.ceil(filteredVideos.length / pageSize)
                                        : 0
                                }
                                page={page}
                                onChange={handleChange}
                                variant="outlined"
                                shape="rounded"
                            />
                            <FormControl
                                size="small"
                            >
                                <Select
                                    value={pageSize}
                                    onChange={handlePageSize}
                                >
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                    <MenuItem value={150}>150</MenuItem>
                                    <MenuItem value={250}>250</MenuItem>
                                </Select>
                            </FormControl>
                        </Paper>
                    </Stack>
                </Stack>
            </Stack>
            <DownloadDialog
                backend_url={""}
                downloadDialogOpen={downloadDialogOpen}
                handleDownloadDialogClose={handleDownloadDialogClose}
                downloadList={downloadList}
                setDownloadList={setDownloadList}
                playerConfig={playerConfig}
            />
        </Page>
    );
}

Video.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Video.defaultProps = {};

export default Video;
