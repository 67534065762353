import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import Page from "../../components/Page";
import SearchField from "../../components/SearchField";

import PlayerInventory from "./PlaybackPlayer";
import PlaybackPlayerSidebar from "./PlaybackPlayer/PlaybackPlayerSidebar";

import { useApp } from "../../context/App";

function Player(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PERMISSION CHECK
    const playbackPlayerAdminRoles = ["admin", "player:admin"];
    // const playbackPlayerEditorRoles = playbackPlayerAdminRoles.concat([
    //     "editor",
    //     "player:editor",
    // ]);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        navigate(rootPath);
    };

    // PLAYER VALUES
    const navigate = useNavigate();
    const { player_id } = useParams();
    const [players, setPlayers] = React.useState(null);
    const [player, setPlayer] = React.useState(null);

    // PLAYER OBJECT
    const [playerConfig, setPlayerConfig] = React.useState(false);
    const getPlayerConfig = async () => {
        if (!playerConfig) {
            const data = client.PlayerConfig();
            setPlayerConfig(data);
        }
        return playerConfig;
    };

    React.useEffect(() => {
        (async () => {
            setSelectedPage("playback_player");
            await getPlayerConfig();
            const data = await client.GetCustomerPlayer({});
            setPlayers(data);
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
        // eslint-disable-next-line
    }, [setSelectedPage, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (players && player_id) {
            const data = players.find((player) => player.id === player_id);
            if (!data) {
                navigate(rootPath);
            } else {
                setPlayer(data);
                setOpenSidebar(true);
            }
        } else {
            setPlayer(null);
            setOpenSidebar(false);
        }
    }, [players, player_id]);

    const drawerWidth = 550;
    const [searchText, setSearchText] = React.useState("");

    const breadcrumbs = () => {
		if (player_id) {
            return [
                {title: 'Wiedergabe', link: '#'},
                {title: 'Player', link: rootPath},
                {title: `${player_id}`, link: `${rootPath}/${player_id}`},
            ]
        } else {
            return [
                {title: 'Wiedergabe', link: '#'},
                {title: 'Player', link: rootPath}
            ]
        }
	}

    return (
        <Page
            pageTitel="Player"
            breadcrumbs={breadcrumbs ? breadcrumbs() : []}
            drawerWidth={drawerWidth}
            headerContentRight={
                <SearchField
                    outlined
                    searchText={searchText}
                    setSearchText={setSearchText} 
                />
            }
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                player && (
                    <PlaybackPlayerSidebar
                        key={player.id}
                        drawerWidth={drawerWidth}
                        player={player}
                        playerConfig={playerConfig}
                    />
                )
            }
        >
            {players && (
                <PlayerInventory
                    rootPath={rootPath}
                    players={players}
                    player_id={player_id}
                    searchText={searchText}
                    navigate={navigate}
                />
            )}
        </Page>
    );
}

Player.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Player.defaultProps = {
    openMenu: false,
};

export default Player;
