import * as React from "react";

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import AlertSlaFilter from "../../components/AlertSlaFilter";


function SortBy({ sort, handleSort }) {
    return (
        <FormControl fullWidth color="primary" size="small" variant="outlined">
            <InputLabel>
                Sortieren nach
            </InputLabel>
            <Select
                value={sort}
                label="sortieren_nach"
                onChange={
                    handleSort
                }
                size="small"
            >
                <MenuItem value="name">
                    Name
                </MenuItem>
                <MenuItem value="city">
                    Stadt
                </MenuItem>
                <MenuItem value="region">
                    Region
                </MenuItem>
                <MenuItem value="country">
                    Land
                </MenuItem>
                <MenuItem value="video_category">
                    Video Kategorie
                </MenuItem>
                <MenuItem value="status">
                    Status
                </MenuItem>
                <MenuItem value="created_at">
                    Anlagedatum
                </MenuItem>
            </Select>
        </FormControl>
    )
};

SortBy.propTypes = {
    sort: PropTypes.string,
    handleSort: PropTypes.func.isRequired,
};

SortBy.defaultProps = {
    sort: "city",
};


function SortDirection({ sortDirection, handleSortDirection }) {
    return (
        <FormControl fullWidth color="primary" size="small" variant="outlined">
            <InputLabel>
                Sortierrichtung
            </InputLabel>
            <Select
                value={sortDirection}
                label="sort_direction"
                onChange={
                    handleSortDirection
                }
                size="small"
                fullWidth
            >
                <MenuItem value="asc">
                    A-z
                </MenuItem>
                <MenuItem value="desc">
                    z-A
                </MenuItem>
            </Select>
        </FormControl>
    )
};

SortDirection.propTypes = {
    sortDirection: PropTypes.string,
    handleSortDirection: PropTypes.func.isRequired,
};

SortDirection.defaultProps = {
    sortDirection: "asc",
};


function Status({ status, handleStatusChange }) {
    return (
        <FormControl fullWidth color="primary" size="small" variant="outlined">
            <InputLabel>
                Verbindungsstatus
            </InputLabel>
            <Select
                value={status}
                label="status_label"
                onChange={
                    handleStatusChange
                }
                size="small"
                fullWidth
            >
                <MenuItem value="none">
                    Alle
                </MenuItem>
                <MenuItem value="healthy">
                    Verbunden
                </MenuItem>
                <MenuItem value="unhealthy">
                    Nicht verbunden
                </MenuItem>
            </Select>
        </FormControl>
    );
};

Status.propTypes = {
    status: PropTypes.string,
    handleStatusChange: PropTypes.func,
};

Status.defaultProps = {};


function Producer({ producer, handleProducerChange }) {
    return (
        <FormGroup>
            <FormControlLabel control={
                <Checkbox
                    checked={producer}
                    onChange={handleProducerChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                        '&.Mui-checked': {
                            color: '#0085E9',
                        },
                    }}
                />
            } label="Alle Produzenten" />
        </FormGroup>
    );
};

Producer.propTypes = {
    producer: PropTypes.bool,
    handleProducerChange: PropTypes.func,
};

Producer.defaultProps = {};


function CameraFilter({
    sort = "city",
    handleSort = () => { },
    sortDirection = "asc",
    handleSortDirection = () => { },
    status = "none",
    handleStatusChange = () => { },
    producer = false,
    handleProducerChange = () => { },
    slaFilterStates = null,
    setSlaFilterStates = () => { },
}) {
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                color="primary"
                id="message-menu-button"
                aria-controls={open ? 'message-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ 
                    padding: 0,
                    height: 40,
                    width: 45,
                    minWidth: 40,
                    boxShadow: 1, 
                    borderRadius: 1, 
                    background: "#fff", 
                    border: "1px solid rgba(0,0,0,.2)"
                }}
            >
                <FilterListIcon />
            </Button>
            <Menu
                id="message-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "message-menu-button",
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding={1}
                    minWidth={'250px'}
                    paddingTop={3}
                >
                    <SortBy sort={sort} handleSort={handleSort} />
                </Stack>
                <Divider style={{ borderWidth: 1, margin: '0px 0px 15px 0px' }} />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding={1}
                    minWidth={'250px'}
                >
                    <SortDirection sortDirection={sortDirection} handleSortDirection={handleSortDirection} />
                </Stack>
                <Divider style={{ borderWidth: 1, margin: '0px 0px 15px 0px' }} />
                <Stack
                     direction="column"
                     justifyContent="flex-start"
                     alignItems="stretch"
                     spacing={2}
                     padding={1}
                     minWidth={'250px'}
                >
                    <AlertSlaFilter iconButton={false} slaFilterStates={slaFilterStates} setSlaFilterStates={setSlaFilterStates} />
                </Stack>
                <Divider style={{ borderWidth: 1, margin: '0px 0px 15px 0px' }} />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding={1}
                    minWidth={'250px'}
                >
                    <Status status={status} handleStatusChange={handleStatusChange} />
                </Stack>
                <Divider style={{ borderWidth: 1, margin: '0px 0px 15px 0px' }} />
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                    padding="0px 20px 15px 20px"
                    minWidth={'250px'}
                >
                    <Producer producer={producer} handleProducerChange={handleProducerChange} />
                </Stack>
            </Menu>
        </div>
    );
}

CameraFilter.propTypes = {
    sort: PropTypes.string,
    handleSort: PropTypes.func.isRequired,
    sortDirection: PropTypes.string,
    handleSortDirection: PropTypes.func.isRequired,
    status: PropTypes.string,
    handleStatusChange: PropTypes.func,
    producer: PropTypes.bool,
    handleProducerChange: PropTypes.func,
    slaFilterStates: PropTypes.object,
    setSlaFilterStates: PropTypes.func,
};

export default CameraFilter;