import * as React from "react";

import { visuallyHidden } from "@mui/utils";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";


function descendingComparator(a, b, orderBy) {
    const aValue = a[orderBy].hasOwnProperty('value') ? a[orderBy].value : a[orderBy];
    const bValue = b[orderBy].hasOwnProperty('value') ? b[orderBy].value : b[orderBy];

    if (!isNaN(aValue) && !isNaN(bValue)) {
        return Number(bValue) < Number(aValue) ? -1 : Number(bValue) > Number(aValue) ? 1 : 0;
    }

    if (bValue < aValue) {
        return -1;
    }
    if (bValue > aValue) {
        return 1;
    }
    return 0;
}

function getMultiComparator(order, orderBys) {
    return (a, b) => {
      for (let i = 0; i < orderBys.length; i++) {
        const orderBy = orderBys[i];
        const compareResult = descendingComparator(a, b, orderBy);
        if (compareResult !== 0) {
          return order === 'desc' ? -compareResult : compareResult;
        }
      }
      return 0;
    };
  }

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        //padding={"small"}
                        sortDirection={orderBy.includes(headCell.id) ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy.includes(headCell.id) ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy.includes(headCell.id) ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.array.isRequired,
};

export default function DynTable(props) {
    const { 
        rows, 
        maxRows = 10, 
        rowsPerPageOptions = [10, 25, 50], 
        sortBy = ["name"], 
        sortDir = "asc", 
        rowHeight = .5, 
        dense = false, 
        headCells, 
        searchText,
        setSelectedRow = () => {},
        setMousePosition = () => {},
    } = props;

    if (!rowsPerPageOptions.includes(maxRows)) {
        rowsPerPageOptions.push(maxRows);
        rowsPerPageOptions.sort((a, b) => a - b);
    }

    const [order, setOrder] = React.useState(sortDir);
    const [orderBy, setOrderBy] = React.useState(sortBy);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(maxRows);
    
    const [selected, setSelected] = React.useState(null);
    const isSelected = (id) => {
        return selected === id;
    };

    React.useEffect(() => {
        setPage(0);
    // eslint-disable-next-line
    }, [searchText]);

    const handleClick = (event, row) => {
        setSelected(row.id.value);
        setSelectedRow({row: row, updated: Date.now()});

        const mouseX = event.clientX; // Horizontale Position der Maus
        const mouseY = event.clientY; // Vertikale Position der Maus
        setMousePosition({x: mouseX, y: mouseY});
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredRows = React.useMemo(() => {
        const lowerSearchText = searchText.toLowerCase();
        return rows.filter((row) =>
            Object.keys(row).some((key) => {
                const cellValue = row[key].value;
                return cellValue && typeof cellValue === 'string' && cellValue.toLowerCase().includes(lowerSearchText);
            })
        );
    }, [rows, searchText, headCells]);

    const visibleRows = React.useMemo(() => {
        const orderBys = Array.isArray(orderBy) ? orderBy : [orderBy];
        
        const sortedRows = stableSort(filteredRows, getMultiComparator(order, orderBys));
        return sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [filteredRows, order, orderBy, page, rowsPerPage]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: "100%", marginTop: 0, paddingTop: 0 }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    <TableBody
                        style={{
                            height: (dense ? 33 : 53) * emptyRows,
                        }}
                    >
                        {visibleRows.map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    onClick={(event) =>
                                        handleClick(event, row)
                                    }
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.id.value}
                                    selected={isSelected(row.id.value)}
                                    sx={{ cursor: "pointer" }}
                                >
                                    {headCells.map((headCell) => (
                                        <TableCell
                                            key={headCell.id}
                                            align={headCell.numeric ? "right" : "left"}
                                            component={headCell.id === "name" ? "th" : "td"}
                                            id={headCell.id === "name" ? `enhanced-table-checkbox-${index}` : undefined}
                                            scope={headCell.id === "name" ? "row" : undefined}
                                            padding="normal"
                                            sx={{
                                                fontSize: headCell.fontSize ? headCell.fontSize : null,
                                                lineHeight: rowHeight,
                                                width: headCell.width ? headCell.width : null,
                                            }}
                                        >
                                            {row[headCell.id].label ? row[headCell.id].label : row[headCell.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                <TablePagination
                    labelRowsPerPage="Ergebnisse pro Seite"
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </Box>
    );
}
