import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import Page from "../../components/Page";
import SearchField from "../../components/SearchField";

import PlaybackUser from "./PlaybackUser";
import PlaybackUserSidebar from "./PlaybackUser/PlaybackUserSidebar";

import { useApp } from "../../context/App";

function Player(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PERMISSION CHECK
    const playbackPlayerAdminRoles = ["admin", "player:admin"];
    // const playbackPlayerEditorRoles = playbackPlayerAdminRoles.concat([
    //     "editor",
    //     "player:editor",
    // ]);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        navigate(rootPath);
    };

    // PLAYBACK USER VALUES
    const navigate = useNavigate();
    const { user_id } = useParams();
    const [users, setUsers] = React.useState(null);
    const [user, setUser] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            setSelectedPage("playback_user");
            const data = await client.GetCustomerPlaybackUser();
            setUsers(data);
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    }, [setSelectedPage, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    React.useEffect(() => {
        if (users && user_id) {
            const data = users.find((user) => user.id === user_id);
            if (!data) {
                navigate(rootPath);
            } else {
                setUser(data);
                setOpenSidebar(true);
            }
        } else {
            setUser(null);
            setOpenSidebar(false);
        }
    }, [users, user_id]);

    const drawerWidth = 400;
    const [searchText, setSearchText] = React.useState("");

    const breadcrumbs = () => {
		if (user_id) {
            return [
                {title: 'Wiedergabe', link: '#'},
                {title: 'Direktzugriff', link: rootPath},
                {title: `${user_id}`, link: `${rootPath}/${user_id}`}
            ]
        } else {
            return [
                {title: 'Wiedergabe', link: '#'},
                {title: 'Direktzugriff', link: rootPath}
            ]
        }
	}

    return (
        <Page
            pageTitel="Direktzugriff"
            drawerWidth={drawerWidth}
            breadcrumbs={breadcrumbs ? breadcrumbs() : []}
            headerContentRight={
                <SearchField
                    outlined
                    searchText={searchText}
                    setSearchText={setSearchText} 
                />
            }
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            sidebarContent={
                user && (
                    <PlaybackUserSidebar
                        key={user.id}
                        drawerWidth={drawerWidth}
                        user={user}
                    />
                )
            }
        >
            {users && (
                <PlaybackUser
                    rootPath={rootPath}
                    users={users}
                    user_id={user_id}
                    searchText={searchText}
                    navigate={navigate}
                />
            )}
        </Page>
    );
}

Player.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Player.defaultProps = {
    openMenu: false,
};

export default Player;
