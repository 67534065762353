import React from "react";

import PropTypes from "prop-types";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import API from "../misc/api";
import useInterval from "../hooks/useInterval";

const Service = React.createContext();

function AppProvider(props) {
    const [client] = React.useState(new API(props.address));
    const [backdrop, setBackdrop] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [$snack, setSnack] = React.useState({
        open: false,
        message: "",
        severity: "info",
        title: "",
    });
    const [openMenu, setOpenMenu] = React.useState(false);

    const handleMenuToggle = () => {
        setOpenMenu(!openMenu);
    };

    const [selectedPage, setSelectedPage] = React.useState("");

    const hasPermission = (requiredRoles) => {
        return client.UserPermissionCheck(requiredRoles);
    };

    const notify = (severity, message) => {
        let logger = console.info;
        let title = severity;
        switch (severity) {
            case "warning":
                logger = console.warn;
                title = "warnung";
                break;
            case "error":
                logger = console.error;
                title = "Fehler";
                break;
            default:
                break;
        }

        setSnack({
            ...$snack,
            open: true,
            message: message,
            severity: severity,
            title: title,
        });

        logger(message);
    };

    useInterval(async () => {
        if (!client.LoggedIn()) {
            return;
        }
        await client.refreshToken();
        return;
    }, 60000);

    client.SetNotificator(notify);

    const handleCloseSnack = () => {
        setSnack({
            ...$snack,
            open: false,
        });
    };

    const value = {
        client,
        hasPermission,
        notify,
        backdrop: (value) => {
            setBackdrop(value);
        },
        openMenu,
        handleMenuToggle,
        selectedPage,
        setSelectedPage,
        // der reload state ist ein boolean, der die Komponente dazu zwingt, sich neu zu rendern
        // der wert ist irrelevant. reloadPage() löst den reload aus, sofern
        // reload innerhalb useEffect in der gewünschten komponente verwendet wird.
        reload,
        reloadPage: () => {
            setReload(!reload);
        },
    };

    return (
        <React.Fragment>
            <Service.Provider value={value}>{props.children}</Service.Provider>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={$snack.open}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
            >
                <Alert
                    variant="filled"
                    elevation={6}
                    onClose={handleCloseSnack}
                    severity={$snack.severity}
                >
                    <AlertTitle>{$snack.title.toUpperCase()}</AlertTitle>
                    {$snack.message}
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{
                    color: "#0084E8",
                    backgroundColor: "rgba(255,255,255, 0.65)",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={backdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </React.Fragment>
    );
}

AppProvider.propTypes = {
    address: PropTypes.string,
    children: PropTypes.node.isRequired,
};

AppProvider.defaultProps = {
    address: "",
    children: null,
};

function useApp() {
    return React.useContext(Service);
}

export { AppProvider, useApp };
