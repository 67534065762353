import React from "react";
import { Link as RouterLink } from 'react-router-dom';

import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from "@mui/icons-material/GridView";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import SettingsIcon from "@mui/icons-material/Settings";


export default function PageMenu(props) {
    const { selectedPage, handleMenuClick, pagePermission, handleSubmenuOpen, handleSubmenuClose } = props;
    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
            height={"100%"}
            marginTop={2}
        >
            <IconButton
                aria-label="dashboard" 
                size="large"
                component={RouterLink}
                to="/dashboard"
                onClick={() => handleMenuClick()}
                disabled={!pagePermission("dashboard")}
                onMouseEnter={() => handleSubmenuClose(true)}
                sx={{
                    backgroundColor:
                        selectedPage === "dashboard"
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                    borderRadius: "8px",
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: "8px",
                    },
                }}
            >
                <GridViewIcon
                    fontSize="inherit"
                    color={pagePermission("dashboard") ? "blue" : "disabled"}
                />
            </IconButton>
            <IconButton
                aria-label="live"
                size="large"
                onMouseEnter={() => handleSubmenuOpen("live")}
                sx={{
                    backgroundColor:
                        selectedPage.startsWith("live_")
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                    borderRadius: "8px",
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: "8px",
                    },
                }}
            >
                <VideocamIcon
                    fontSize="inherit"
                    color="blue"
                />
            </IconButton>
            <IconButton
                aria-label="video"
                disabled={!pagePermission("video")}
                size="large"
                onMouseEnter={() => handleSubmenuOpen("video")}
                sx={{
                    backgroundColor:
                        selectedPage.startsWith("video_")
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                    borderRadius: "8px",
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: "8px",
                    },
                }}
            >
                <VideoFileIcon
                    fontSize="inherit"
                    color={pagePermission("video") ? "blue" : "disabled"}
                />
            </IconButton>
            <IconButton
                aria-label="playback"
                size="large"
                onMouseEnter={() => handleSubmenuOpen("playback")}
                sx={{
                    backgroundColor:
                        selectedPage.startsWith("playback_")
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                    borderRadius: "8px",
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: "8px",
                    },
                }}
            >
                <PlayCircleIcon
                    fontSize="inherit"
                    color={"blue"}
                />
            </IconButton>
            <IconButton
                aria-label="analytics"
                size="large"
                onMouseEnter={() => handleSubmenuOpen("analytics")}
                sx={{
                    backgroundColor:
                        selectedPage.startsWith("analytics_")
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                    borderRadius: "8px",
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: "8px",
                    },
                }}
            >
                <AssessmentIcon 
                    fontSize="inherit" 
                    color={"blue"}
                />
            </IconButton>
            <IconButton
                aria-label="alert"
                size="large"
                onMouseEnter={() => handleSubmenuOpen("alert")}
                sx={{
                    backgroundColor:
                        selectedPage.startsWith("alert_")
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                    borderRadius: "8px",
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: "8px",
                    },
                }}
            >
                <ReportProblemIcon
                    fontSize="inherit"
                    color={"blue"}
                />
            </IconButton>
            <IconButton
                aria-label="admin"
                size="large"
                onMouseEnter={() => handleSubmenuOpen("admin")}
                sx={{
                    backgroundColor:
                        selectedPage.startsWith("admin_")
                            ? "rgba(0,0,0,.065)"
                            : "transparent",
                    borderRadius: "8px",
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: "8px",
                    },
                }}
            >
                <SettingsIcon
                    fontSize="inherit"
                    color="blue"
                />
            </IconButton>
        </Stack>
    );
}