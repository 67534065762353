import * as React from "react";
import { Link } from 'react-router-dom';

import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PropTypes from "prop-types";

import { useApp } from "../../../context/App";

function VideoSidebarMenu({ videoAdminRoles, videoEditorRoles, video }) {
    const { hasPermission } = useApp();
    const [anchorEl, setAnchorEl] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const cameraViewerRoles = [
        "admin",
        "viewer",
        "editor",
        "camera:admin",
        "camera:viewer",
        "camera:editor",
    ];

    return (
        <div>
            <IconButton
                color="primary"
                id="message-menu-button"
                aria-controls={open ? "message-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="message-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "message-menu-button",
                }}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList>
                    <MenuItem
                    component={Link}
                        to={`/live/camera/${video.camera_id}`}
                        disabled={!hasPermission(cameraViewerRoles)}
                    >
                        <ListItemIcon>
                            <OpenInNewIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Kamera anzeigen</ListItemText>
                    </MenuItem>
                    {/* <MenuItem
                        onClick={handleClose}
                        disabled={!hasPermission(cameraEditorRoles)}
                    >
                        <ListItemIcon>
                            <ReportProblemIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Störung melden</ListItemText>
                    </MenuItem>                    */}
                    <Divider style={{ borderTop: 1 }} />
                    <MenuItem onClick={handleClose} disabled>
                        <ListItemIcon>
                            <ModeEditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Ablaufdatum ändern</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose} disabled>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Löschen</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </div>
    );
}

VideoSidebarMenu.propTypes = {
    videoAdminRoles: PropTypes.array,
    videoEditorRoles: PropTypes.array,
    video: PropTypes.object.isRequired,
};

VideoSidebarMenu.defaultProps = {
    videoAdminRoles: [],
    videoEditorRoles: [],
};

export default VideoSidebarMenu;
