import React, { useState, useEffect } from "react";
import countries from "i18n-iso-countries";
import { Autocomplete, TextField } from "@mui/material";

countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

function AnalyticsCountrySelect({ apiQuery, setApiQuery }) {
    const [countryList, setCountryList] = useState([]);

    useEffect(() => {
        setCountryList(Object.keys(countries.getNames("de", { select: "official" })));
    }, []);

    return (
        <Autocomplete
            value={apiQuery.country_iso && apiQuery.country_iso !== "" ? apiQuery.country_iso : null}
            size="small"
            onChange={(_, newValue) => setApiQuery(prevApiQuery => ({ ...prevApiQuery, country_iso: newValue ? newValue : "" }))}
            options={countryList}
            getOptionLabel={(option) => countries.getName(option, "de") || ""}
            renderInput={(params) => (
                <TextField 
                    {...params}
                    label={(apiQuery.country_iso && apiQuery.country_iso !== "") ? null : "Land"} 
                    variant="outlined" 
                    fullWidth
                    color={(apiQuery.country_iso && apiQuery.country_iso !== "") ? "secondary" : "primary"}
                    sx={{
                        width: (apiQuery.country_iso && apiQuery.country_iso !== "") ? "200px" : null,
                    }}
                />
            )}
        />
    );
}

AnalyticsCountrySelect.propTypes = {};

AnalyticsCountrySelect.defaultProps = {};

export default AnalyticsCountrySelect;
