import { createTheme } from "@mui/material/styles";

import base from "./base";
import globals from "./global";

import accordion from "./components/accordion";
import accordionSummary from "./components/accordionSummary";
import alert from "./components/alert";
import appBar from "./components/appBar";
import autocomplete from "./components/autocomplete";
import backdrop from "./components/backdrop";
import breadcrumb from "./components/breadcrumb";
import button from "./components/button";
import buttonGroup from "./components/buttonGroup";
import checkobox from "./components/checkbox";
import chip from "./components/chip";
import divider from "./components/divider";
import dialog from "./components/dialog";
import fab from "./components/fab";
import formControlLabel from "./components/formControlLabel";
import formLabel from "./components/formLabel";
import input from "./components/input";
import inputBase from "./components/inputBase";
import inputLabel from "./components/inputLabel";
import iconButton from "./components/iconButton";
import link from "./components/link";
import listItem from "./components/listItem";
import listSubheader from "./components/listSubheader";
import menu from "./components/menu";
import menuItem from "./components/menuItem";
import modal from "./components/modal";
import outlinedInput from "./components/outlinedInput";
import paper from "./components/paper";
import pickersday from "./components/pickersday";
import popover from "./components/popover";
import select from "./components/select";
import tab from "./components/tab";
import tabs from "./components/tabs";
import tableRow from "./components/tableRow";
import tooltip from "./components/tooltip";
import ctypography from "./components/typography";

// https://mui.com/customization/default-theme/

const theme = createTheme({
    ...base,

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                ...globals,
            },
        },
        MuiAccordion: { ...accordion },
        MuiAccordionSummary: { ...accordionSummary },
        MuiAlert: { ...alert },
        MuiAppBar: { ...appBar },
        MuiAutocomplete: { ...autocomplete },
        MuiBackdrop: { ...backdrop },
        MuiBreadcrumbs: { ...breadcrumb },
        MuiButton: { ...button },
        MuiButtonGroup: { ...buttonGroup },
        MuiCheckbox: { ...checkobox },
        MuiChip: { ...chip },
        MuiDialog: { ...dialog },
        MuiDivider: { ...divider },
        MuiFab: { ...fab },
        MuiFormControlLabel: { ...formControlLabel },
        MuiFormLabel: { ...formLabel },
        MuiInput: { ...input },
        MuiInputBase: { ...inputBase },
        MuiInputLabel: { ...inputLabel },
        MuiIconButton: { ...iconButton },
        MuiLink: { ...link },
        MuiListItem: { ...listItem },
        MuiListSubheader: { ...listSubheader },
        MuiMenu: { ...menu },
        MuiMenuItem: { ...menuItem },
        MuiModal: { ...modal },
        MuiOutlinedInput: { ...outlinedInput },
        MuiPaper: { ...paper },
        MuiPickersDay: { ...pickersday },
        MuiPopover: { ...popover },
        MuiSelect: { ...select },
        MuiTab: { ...tab },
        MuiTabs: { ...tabs },
        MuiTableRow: { ...tableRow },
        MuiTooltip: { ...tooltip },
        MuiTypography: { ...ctypography },
    },
    breakpoints: {
        // Define custom breakpoint values.
        // These will apply to Material-UI components that use responsive
        // breakpoints, such as `Grid` and `Hidden`. You can also use the
        // theme breakpoint functions `up`, `down`, and `between` to create
        // media queries for these breakpoints
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1920,
        }
      }
});

export default theme;
