import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "moment-timezone";
import moment from "moment";
import "moment/locale/de";

import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import Page from "../../components/Page";

import { useApp } from "../../context/App";

import AnalyticsTableChart from "./AnalyticsTableChart";
import AnalyticsFilterBar from "./AnalyticsFilterBar";
import AnalyticsShare from "./AnalyticsShare";
import AnalyticsShareButton from "./AnalyticsShareButton";


function Analytics(props) {
    const { rootPath } = props;
    const navigate = useNavigate();
    const { client, backdrop, setSelectedPage, hasPermission, reload } = useApp();
    const [ready, setReady] = React.useState(false);
    const [readyAssets, setReadyAssets] = React.useState(false);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        setOpenSidebar(!openSidebar);
        navigate(rootPath);
    };

    // PERMISSION CHECK
    // const analyticsAdminRoles = ["admin"];
    // const analyticsEditorRoles = analyticsAdminRoles;

    const cameraRoles = [
        "admin",
        "editor",
        "viewer",
        "camera:viewer",
        "camera:editor",
        "camera:admin",
    ];
    const playbackPlayerRoles = [
        "admin",
        "editor",
        "viewer",
        "player:viewer",
        "player:editor",
        "player:admin",
    ];
    const playbackUserRoles = [
        "admin",
        "editor",
        "viewer",
        "player:viewer",
        "player:editor",
        "player:admin",
    ];
    const analyticsRoles = [
        "admin",
        "editor",
        "viewer",
        "analytics:viewer",
    ];
    const adminRoles = ["admin"];

    // Berechnung von Start- und Endzeitpunkt
    function getDate(date) {
        //const momentDate = moment(date).minutes(0).seconds(0);
        const momentDate = moment(date).hours(0).minutes(0).seconds(0);
        return momentDate.format("YYYY-MM-DD");
    }

    const maxDaysForHour = 0;

    // Get share parameter
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const shareString = params.get('share');
    const playerId = params.get('player_id');
    const cameraId = params.get('camera_id');


    // API-Query
    const [apiQuery, setApiQuery] = React.useState({
        camera_id: cameraId,
        player_id: playerId,
        content_type: "m3u8",
        content_profile: null,
        device_type: ["Desktop", "Mobile", "Tablet", "TV", "Unknown"],
        country_iso: null,
        region_iso: null,
        segment1: "playback_user",
        dimension1: "playback_user_id",
        dimension2: null,
        dimension3: null,
        from_date: moment().hours(0).minutes(0).seconds(0).subtract(9, "days"),
        to_date: moment().hours(0).minutes(0).seconds(0).subtract(1, "days"),
        step: "day",
    });

    const handleChangeRange = (from_date, to_date, step) => {
        setApiQuery(prevApiQuery => (
            {
                ...prevApiQuery,
                from_date: from_date,
                to_date: to_date,
                step: step
            }
        ))
    };

    const setStep = () => {
        const diff = moment(apiQuery.to_date).diff(
            moment(apiQuery.from_date),
            "days",
        );
        if (diff > maxDaysForHour && apiQuery.step === "days") {
            setApiQuery(prevApiQuery => (
                {
                    ...prevApiQuery,
                    step: "day"
                }
            ))
        }
    };

    const [metrics, setMetrics] = React.useState({ metrics: [] });
    const getMetrics = async () => {
        const params = { ...apiQuery };
        params.from_date = getDate(params.from_date);
        params.to_date = getDate(params.to_date);
        params.region_iso = params.region_iso
            ? params.region_iso.split("-")[1]
            : null;
        return await client.PostCustomerAnalyticsHistory(params);
    };

    const [assets, setAssets] = React.useState({
        cameras: null,
        players: null,
        users: null,
        playback_users: null,
    });

    const [storageDateRange, setStorageDateRange] = React.useState(null);

    const getPlayers = async () => {
        return await client.GetCustomerPlayer({ limit: 0 });
    };

    const getCameras = async () => {
        return await client.GetCustomerCamera({ limit: 0 });
    };

    const getUsers = async () => {
        return await client.GetCustomerUser({ limit: 0 });
    };

    const getPlaybackUsers = async () => {
        return await client.GetCustomerPlaybackUser({ limit: 0 });
    };

    // Setzt alle Defaults beim laden der Seite
    React.useEffect(() => {
        (async () => {
            setSelectedPage("analytics_playback_user");
            let cameras = [];
            if (hasPermission(cameraRoles)) {
                cameras = await getCameras();
            }
            let players = [];
            let playback_users = [];
            if (hasPermission(playbackPlayerRoles)) {
                players = await getPlayers();
                playback_users = await getPlaybackUsers();
            }
            let users = [];
            if (hasPermission(adminRoles)) {
                users = await getUsers();
            }
            setAssets({
                ...assets,
                cameras: cameras,
                players: players,
                users: users,
                playback_users: playback_users,
            });
            if (shareString) {
                const sharedApiQuery = AnalyticsShare({ 
                    crypt: false, apiQueryUncrypt: null, apiQueryCrypt: shareString 
                });
                if (sharedApiQuery) {
                    setApiQuery(sharedApiQuery);
                };
            };
            setReadyAssets(true);
            return () => {
                setReadyAssets(false);
            };
        })();
        // eslint-disable-next-line
    }, [reload]);

    React.useEffect(() => {
        (async () => {
            backdrop(true);
            setReady(false);
            setStep();
            const data = await getMetrics();
            setMetrics(data);
            setStorageDateRange(data.storage_session_range);
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
        // eslint-disable-next-line
    }, [apiQuery]);

    return (
        <Page
            pageTitel="Direktzugriff Analytics"
			breadcrumbs={[
                {title: 'Analytics', link: '#'},
                {title: 'Direktzugriff', link: rootPath}
            ]}
            headerContentRight={<AnalyticsShareButton apiQuery={apiQuery} />}
            drawerWidth={550}
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
        >
            {assets && assets.cameras && metrics && storageDateRange && (
                <AnalyticsFilterBar
                    apiQuery={apiQuery}
                    setApiQuery={setApiQuery}
                    assets={assets}
                    maxDaysForHour={maxDaysForHour}
                    storageDateRange={storageDateRange}
                    onChangeRange={handleChangeRange}
                />
            )}
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
                width={"100%"}
            >
                {assets && assets.cameras && metrics && (
                    <AnalyticsTableChart
                        key={apiQuery}
                        apiQuery={apiQuery}
                        metrics={metrics}
                        assets={assets}
                        playerConfig={client.PlayerConfig()}
                        ready={ready}
                        readyAssets={readyAssets}
                        backdrop={backdrop}
                        onChangeRange={handleChangeRange}
                        maxDaysForHour={maxDaysForHour}
                    />
                )}
            </Stack>
        </Page>
    );
}

Analytics.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

Analytics.defaultProps = {
    openMenu: false,
};

export default Analytics;
