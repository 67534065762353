import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import IconButton from "@mui/material/IconButton";
import Link from '@mui/material/Link';

import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useApp } from "../context/App";

function PageHeader(props) {
    const { handleMenuToggle } = useApp();

    const theme = useTheme();
    const downMd = useMediaQuery(theme.breakpoints.down("md"));

    // props.breakcrumbs = [ {title: String, link: String } ]
    const breadcrumbs = props.breadcrumbs.map((item, index) => {
        return (
            <Link
                component={RouterLink}
                underline="hover"
                key={index}
                color={'rgba(0, 0, 0, 0.8)'}
                to={item.link}
            >
                {item.title}
            </Link>
        );
    });

    return (
        <Stack
            direction={downMd ? "column" : "row"}
            justifyContent="space-between"
            alignItems={downMd ? "flex-start" : "center"}
            spacing={2}
            marginLeft={-1}
            paddingLeft={-5}
            width={"100%"}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
            >
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleMenuToggle}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        marginRight: 2,
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={0}
                >
                    <Typography
                        variant="h1"
                        fontWeight={500}
                        fontSize={"2.8rem"}
                        color={'blue'}
                    >
                        {props.pageTitel}
                    </Typography>
                    <Breadcrumbs separator="›" aria-label="breadcrumb" marginTop={-.5}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems={downMd ? "center" : "flex-start"}
                spacing={1}
                height={"58px"}
                width={downMd ? "100%" : "50%"}
            >
                {props.contentLeft}
                {props.contentRight}
            </Stack>
        </Stack>
    );
}

PageHeader.propTypes = {
    openSidebar: PropTypes.bool,
    handleSidebarOpen: PropTypes.func,
    contentLeft: PropTypes.object,
    contentRight: PropTypes.object,
    pageTitel: PropTypes.string,
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            link: PropTypes.string.isRequired,
        })
    ),
};

PageHeader.defaultProps = {
    openSidebar: false,
    handleSidebarOpen: null,
    contentLeft: null,
    contentRight: null,
    pageTitel: null,
    breadcrumbs: [],
};

export default PageHeader;
