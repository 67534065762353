import React from "react";
import { Link as RouterLink } from 'react-router-dom';
import debounce from 'lodash.debounce';

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Link from "@mui/material/Link";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";


export function PageSubMenuAds(props) {
    const { hoveredPage } = props;
    let menu = (
        <Stack
            justifyContent="flex-start"
            alignItems="center"
            sx={{
                color: 'white',
                backgroundColor: "#0085E9",
                padding: "10px 15px 10px 15px",
                borderRadius: 2,
            }}
        >
            <Typography
                fontWeight={400}
                fontSize={".85rem"}
                lineHeight={1.2}
                wordWrap={"break-word"}
            >
                Weitere Informationen zu Webcams finden Sie in unserer 
                {' '}<Link href="https://docs.livespotting.com/" sx={{fontWeight: 600, color: "inherit", textDecoration: "underline"}} target="_blank" rel="noopener">Wissensdatenbank</Link>{' '}
                und auf unserer
                {' '}<Link href="https://livespotting.com/" sx={{fontWeight: 600, color: "inherit", textDecoration: "underline"}} target="_blank" rel="noopener">Webseite</Link>
                .
            </Typography>
        </Stack>
    );
    return menu;
}


function PageSubMenuLink(props) {
    const { items, selectedPage, handleMenuClick, pagePermission, handleMenuDescription } = props;
    return (
        <List dense sx={{width: '100%'}}>
            {items.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ width: '100%', backgroundColor: 'white' }}>
                    <ListItemButton
                        sx={{ 
                            padding: 0,
                            "&.Mui-selected": {
                                backgroundColor: "rgba(0, 0, 0, 0.035)",
                                color: "#0085E9",
                                borderRadius: '4px',
                            },
                            "&:hover": {
                                backgroundColor: "rgba(0, 0, 0, 0.025)",
                                borderRadius: '4px',
                            },
                        }}
                        component={RouterLink}
                        to={item.link}
                        onClick={() => handleMenuClick()}
                        onMouseEnter={() => handleMenuDescription(item.description)}
                        onMouseLeave={() => handleMenuDescription(null)}
                        disabled={!pagePermission(item.permission)}
                        selected={selectedPage === item.selected}
                    >
                        <ListItemIcon sx={{ minWidth: .2 }}>
                            <KeyboardArrowRightIcon
                                sx={{
                                    color: selectedPage === item.selected ? "#0085E9" : "black",
                                    fontSize: "1.5rem",
                                    marginRight: 0,
                                    marginLeft: 1,
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
}

export function PageSubMenuItem(props) {
    const { menuIcon, menuName, menuDescription, menuItems, selectedPage, handleMenuClick, pagePermission } = props;
    const [description, setDescription] = React.useState(menuDescription);
    const handleMenuDescription = React.useCallback(
        debounce((text) => {
            if (description !== text) {
                setDescription(text ? text : menuDescription);
            }
        }, 200),
        [description, menuDescription]
    );
    return (
        <>
            <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                textAlign={"right"}
                spacing={0}
                width={"100%"}
            >
                {menuIcon}
            </Stack>
            <Typography
                fontWeight={600}
                fontSize={"2rem"}
                color={'black'}
                lineHeight={1}
                paddingBottom={.5}
                paddingTop={1.5}
                sx={{ whiteSpace: 'pre-line' }}
            >
                {menuName}
            </Typography>
            <Divider sx={{width: "100%", border: "0px solid rgba(0,0,0,.1)", borderTop: "1px solid rgba(0,0,0,.2)"}} />
            {/* <Typography
                fontWeight={400}
                fontSize={".85rem"}
                paddingTop={1}
                paddingBottom={1}
                paddingLeft={2}
                paddingRight={2}
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.035)',
                    borderRadius: '4px',
                }}
            >
                {description}
            </Typography> */}
            <PageSubMenuLink items={menuItems} selectedPage={selectedPage} handleMenuClick={handleMenuClick} pagePermission={pagePermission} handleMenuDescription={handleMenuDescription} />
        </>
    );
}

export default function PageSubMenu(props) {
    const { hoveredPage, selectedPage, handleMenuClick, pagePermission } = props;
    let menu = null;
    if (hoveredPage === "live") {
        menu = (
            <PageSubMenuItem
                menuIcon={<VideocamIcon
                    fontSize="inherit"
                    color={"blue"}
                    sx={{
                        fontSize: "2.75rem",
                        marginTop: 2,
                        marginBottom: -1,
                        marginRight: '-4px',
                    }}
                />}
                menuName="Live"
                menuDescription={`Verwalten Sie Live-Signale von Kameras bis zur finalen Ausgabe.`}
                menuItems={[
                    {name: "Kamera", description: "Übersicht und Steuerung der Kameras.", link: "/live/camera", permission: "camera", selected: "live_camera"},
                    {name: "Restreaming", description: "Weiterleitung von Live-Signalen.", link: "/live/restream", permission: "restream", selected: "live_restream"},
                    {name: "Playout", description: "Konfiguration von.", link: "/live/playout", permission: "playout", selected: "live_playout"},
                ]}
                selectedPage={selectedPage}
                pagePermission={pagePermission}
                handleMenuClick={handleMenuClick}
            />
        );
        
    } else if (hoveredPage === "video") {
        menu = (
            <PageSubMenuItem
                menuIcon={<VideoFileIcon
                    fontSize="inherit"
                    color={"blue"}
                    sx={{
                        fontSize: "2.75rem",
                        marginTop: 2,
                        marginBottom: -1,
                        marginRight: '-8px',
                    }}
                />}
                menuName={`Video`}
                menuDescription={`Verwalten Sie alle produzierten oder hochgeladenen Videodateien.`}
                menuItems={[
                    {name: "Kamera Aufnahmen", link: "/video/camera", permission: "video", selected: "video_camera"},
                    {name: "Eigene Clips", link: "/video/user", permission: "video_user", selected: "video_user"},
                ]}
                selectedPage={selectedPage}
                pagePermission={pagePermission}
                handleMenuClick={handleMenuClick}
            />
        );
    } else if (hoveredPage === "playback") {
        menu = (
            <PageSubMenuItem
                menuIcon={<PlayCircleIcon
                    fontSize="inherit"
                    color={"blue"}
                    sx={{
                        fontSize: "2.75rem",
                        marginTop: 2,
                        marginBottom: -1,
                        marginRight: '-4px',
                    }}
                />}
                menuName={`Wiedergabe`}
                menuDescription={`Alles rund um den Zugriff auf Ihren Video-Content.`}
                menuItems={[
                    {name: "Player", link: "/playback/player", permission: "playback_player", selected: "playback_player"},
                    {name: "Direktzugriff", link: "/playback/user", permission: "playback_user", selected: "playback_user"},
                ]}
                selectedPage={selectedPage}
                pagePermission={pagePermission}
                handleMenuClick={handleMenuClick}
            />
        );
    } else if (hoveredPage === "analytics") {
        menu = (
            <PageSubMenuItem
                menuIcon={<AssessmentIcon
                    fontSize="inherit"
                    color={"blue"}
                    sx={{
                        fontSize: "2.75rem",
                        marginTop: 2,
                        marginBottom: -1,
                        marginRight: '-4px',
                    }}
                />}
                menuName={`Analytics`}
                menuDescription={`Erhalten Sie systemweite Statistiken und Auswertungen.`}
                menuItems={[
                    {name: "Kamera", link: "/analytics/camera", permission: "analytics", selected: "analytics_camera"},
                    {name: "Player", link: "/analytics/playback_player", permission: "analytics", selected: "analytics_playback_player"},
                    {name: "Direktzugriff", link: "/analytics/playback_user", permission: "analytics", selected: "analytics_playback_user"},
                    {name: "Zugang", link: "/analytics/user", permission: "analytics", selected: "analytics_user"},
                ]}
                selectedPage={selectedPage}
                pagePermission={pagePermission}
                handleMenuClick={handleMenuClick}
            />
        );
    } else if (hoveredPage === "alert") {
        menu = (
            <PageSubMenuItem
                menuIcon={<ReportProblemIcon
                    fontSize="inherit"
                    color={"blue"}
                    sx={{
                        fontSize: "2.75rem",
                        marginTop: 2,
                        marginBottom: -1,
                        marginRight: '0px',
                    }}
                />}
                menuName={`Störungen`}
                menuDescription={`Verwalten Sie alle Störungen und Benachrichtigungen.`}
                menuItems={[
                    {name: "Kamera", link: "/alert/camera", permission: "alert", selected: "alert_camera"},
                    {name: "System", link: "/alert/system", permission: "alert_system", selected: "alert_system"},
                ]}
                selectedPage={selectedPage}
                pagePermission={pagePermission}
                handleMenuClick={handleMenuClick}
            />
        );
    } else if (hoveredPage === "admin") {
        menu = (
            <PageSubMenuItem
                menuIcon={<SettingsIcon
                    fontSize="inherit"
                    color={"blue"}
                    sx={{
                        fontSize: "2.75rem",
                        marginTop: 2,
                        marginBottom: -1,
                        marginRight: '-4px',
                    }}
                />}
                menuName={`Einstellung`}
                menuDescription={`Konfigurieren Sie alle Systemeinstellungen.`}
                menuItems={[
                    {name: "Profil", link: "/admin/profile", permission: "admin", selected: "admin_profile"},
                    {name: "Zugang", link: "/admin/user", permission: "admin", selected: "admin_user"},
                    {name: "ACL", link: "/admin/acl", permission: "admin", selected: "admin_acl"},
                    {name: "Protokoll", link: "/admin/protocol", permission: "admin", selected: "admin_protocol"},
                    {name: "Abrechnung", link: "/admin/billing", permission: "admin", selected: "admin_billing"},
                ]}
                selectedPage={selectedPage}
                pagePermission={pagePermission}
                handleMenuClick={handleMenuClick}
            />
        );
    }
    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            height={"100%"}
            width={"100%"}
        >
            {menu}
        </Stack>
    );
}