import React, { useState, useEffect } from "react";
import countries from "i18n-iso-countries";
import iso3166 from "iso-3166-2";
import { Autocomplete, TextField } from "@mui/material";

countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

function AnalyticsRegionSelect({ apiQuery, setApiQuery }) {
    const [regionList, setRegionList] = useState({});

    const country = apiQuery.country_iso;
    const region = apiQuery.region_iso;

    useEffect(() => {
        if (country) {
            const countryInfo = iso3166.country(country);
            if (countryInfo) {
                setRegionList(countryInfo.sub);
            } else {
                setRegionList({});
                setApiQuery((prevApiQuery) => ({
                    ...prevApiQuery,
                    region_iso: "",
                }));
            }
        }
    }, [country, setApiQuery]);

    return (
        <Autocomplete
            value={region}
            size="small"
            onChange={(_, newValue) => {
                setApiQuery((prevApiQuery) => ({
                    ...prevApiQuery,
                    region_iso: newValue ? newValue : "",
                }));
            }}
            options={Object.keys(regionList)}
            getOptionLabel={(option) => regionList[option]?.name || ""}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={region && region !== "" ? null : "Region"}
                    variant="outlined"
                    color={region && region !== "" ? "secondary" : "primary"}
                    sx={{
                        width: region && region !== "" ? "200px" : null,
                    }}
                />
            )}
        />
    );
}

AnalyticsRegionSelect.propTypes = {};

AnalyticsRegionSelect.defaultProps = {};

export default AnalyticsRegionSelect;
