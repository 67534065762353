import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useApp } from "../../../context/App";

function PlayoutSelect({ playout, setPlayout }) {
    const handleChange = (event) => {
        setPlayout(event.target.value);
    };

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="playout-select-label">Ausfallschutz</InputLabel>
            <Select
                labelId="playout-select-label"
                id="playout-select"
                value={playout}
                label="Ausfallschutz"
                onChange={handleChange}
                size="small"
                readOnly
            >
                <MenuItem value={"video"}>Video</MenuItem>
                <MenuItem value={"video_audio"}>Video und Audio</MenuItem>
                <MenuItem value={"none"}>Ohne</MenuItem>
            </Select>
        </FormControl>
    );
}

PlayoutSelect.propTypes = {
    playout: PropTypes.string.isRequired,
    setPlayout: PropTypes.any,
};

PlayoutSelect.defaultProps = {};

function Stream({ input }) {
    const [playout, setPlayout] = React.useState(null);
    if (!playout) {
        if (
            input.address.includes("playout:") &&
            input.options.includes("-playout_audio")
        ) {
            setPlayout("video_audio");
        } else if (
            input.address.includes("playout:") &&
            !input.options.includes("-playout_audio")
        ) {
            setPlayout("video");
        } else {
            setPlayout("none");
        }
    }

    return (
        <React.Fragment>
            <Grid item xs={3}>
                <PlayoutSelect playout={playout} setPlayout={setPlayout} />
            </Grid>
            <Grid item xs={9}>
                <TextField
                    label={`Stream ${input.id}`}
                    value={input.address.replace("playout:", "")}
                    disabled={!input.address}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
        </React.Fragment>
    );
}

Stream.propTypes = {
    input: PropTypes.string.isRequired,
};

Stream.defaultProps = {};


function Streaming({ camera, playerConfig, loading, ingest }) {

    if (loading) {
        return (
            <React.Fragment>
                <Typography marginBottom={1.5} variant="h4">
                    Streaming
                </Typography>
                <Stack
                    style={{
                        padding: "0px 15px 15px 15px",
                        marginBottom: 50,
                        backgroundColor: "rgba(0,0,0,.035)",
                        borderRadius: 4,
                    }}
                    spacing={0}
                >
                    <CircularProgress color="inherit" size={20} />
                </Stack>
            </React.Fragment>
        );
    }

    // {ingest.service}://wettercom-c1.livespotting.com/{cid}/{camera_id}.stream/{camera_id}_token:{token}
    const ingestingEndpointUrl = () => {
        if (ingest.mode === "passive") {
            const url = playerConfig.url.replace(
                "https",
                ingest.service
            ).replace("/memfs", "") + camera.id + ".stream/" + camera.id + "_token:" + ingest.token;
            return url;
        }
        return "";
    }

    return (
        <React.Fragment>
            <Typography marginTop={0.5} marginBottom={1.5} variant="h4">
                Streaming
            </Typography>
            <Stack
                style={{
                    padding: "10px 15px 15px 15px",
                    marginBottom: 50,
                    backgroundColor: "rgba(0,0,0,.035)",
                    borderRadius: 4,
                }}
                spacing={0}
            >            
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                >
                    <Grid item xs={4}>
                        <TextField
                            label="Modus"
                            value={ingest.mode}
                            disabled={!ingest.mode}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Service"
                            value={ingest.service}
                            disabled={!ingest.service}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    {ingest.service &&
                        (ingest.service === "rtmp" || ingest.service === "srt") && (
                            <Grid item xs={12}>
                                <TextField
                                    label="Token"
                                    value={ingest.token}
                                    disabled={!ingest.token}
                                    size="small"
                                    readOnly
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid>
                        )}
                    <Grid item xs={12}>
                        <TextField
                            label="Encoding Template"
                            value={ingest.encoding_template}
                            disabled={!ingest.encoding_template}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        Kamera Streams:
                    </Grid>
                    {ingest.input_config &&
                        ingest.input_config.map((input) => (
                            <Stream key={input.id} input={input} />
                        ))
                    }
                    {/* <Grid item xs={12}>
                        <TextField label="Arbeiterkonfiguration" value={JSON.stringify(ingest.worker_config)} disabled={!ingest.worker_config} size="small" readOnly variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Alarmierungskonfiguration" value={JSON.stringify(ingest.alert_config)} disabled={!ingest.alert_config} size="small" readOnly variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Wartungmodus" value={ingest.maintenance} disabled={!ingest.maintenance} size="small" readOnly variant="standard" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label="Wartungsende" value={ingest.maintenance_ends_at} disabled={!ingest.maintenance_ends_at} size="small" readOnly variant="standard" fullWidth />
                    </Grid> */}
                </Grid>
            </Stack>
            {ingestingEndpointUrl() !== "" && (
                <Stack
                    style={{
                        padding: "10px 15px 15px 15px",
                        marginTop: -40,
                        marginBottom: 50,
                        backgroundColor: "rgba(0,0,0,.035)",
                        borderRadius: 4,
                    }}
                    spacing={0}
                >            
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={1}
                    >
                        <Grid item xs={12}>
                            <TextField
                                label="RTMP Ingesting URL"
                                value={ingestingEndpointUrl()}
                                disabled={ingestingEndpointUrl() === ""}
                                size="small"
                                readOnly
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Stack>
            )}
        </React.Fragment>
    );
}

Streaming.propTypes = {
    camera: PropTypes.object.isRequired,
    playerConfig: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    ingest: PropTypes.object,
};

Streaming.defaultProps = {};


function RecordingItem({ recording }) {
    if (!recording) {
        return null;
    }
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1}
        >
            <Grid item xs={3}>
                <TextField
                    label="ID"
                    value={recording.id}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label="Videolänge (Sekunden)"
                    value={recording.duration_seconds}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Aufnahmeintervall (Sekunden)"
                    value={recording.interval_seconds}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
        </Grid>
    ); 

}


function Recording({ camera, playerConfig, loading, ingest }) {

    // "worker_config": {
    //     "axis_vod": [
    //         "vv73_30_01"
    //     ],
    //     "recording": [
    //         {
    //             "id": "0",
    //             "duration_seconds": 30,
    //             "interval_seconds": 320
    //         }
    //     ]
    // },

    return (
        <React.Fragment>
            <Typography marginTop={-4} marginBottom={1.5} variant="h4">
                Aufnahmen
            </Typography>
            <Stack
                style={{
                    padding: "10px 15px 15px 15px",
                    marginBottom: 50,
                    backgroundColor: "rgba(0,0,0,.035)",
                    borderRadius: 4,
                }}
                spacing={0}
            >
                {loading && (
                    <CircularProgress color="inherit" size={20} />
                )}
                {(!loading && !ingest.worker_config?.recording) && (
                    camera.sla ? "Nicht eingerichtet." : "Nicht verfügbar."
                )}
                {(!loading && ingest.worker_config?.recording) && ingest.worker_config.recording.map((recording) => (
                    <RecordingItem key={recording.id} recording={recording} />
                ))} 
            </Stack>
        </React.Fragment>
    );
}

Recording.propTypes = {
    camera: PropTypes.object.isRequired,
    playerConfig: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    ingest: PropTypes.object,
};

Recording.defaultProps = {};


function CameraSettings({ camera, playerConfig }) {
    const { client } = useApp();
    const [ingest, setIngest] = React.useState(null);
    const loading = !ingest;

    React.useEffect(() => {
        if (!loading) {
            return undefined;
        }
        (async () => {
            if (!ingest) {
                try {
                    const data = await client.GetCustomerCameraIdIngest({
                        camera_id: camera.id,
                    });
                    setIngest(data);
                } catch (error) {
                    console.log(error.response.data);
                }
            }
        })();
        return () => {};
        // eslint-disable-next-line
    }, [ingest]);

    return (
        <React.Fragment>
            <Streaming camera={camera} playerConfig={playerConfig} loading={loading} ingest={ingest} />
            <Recording camera={camera} playerConfig={playerConfig} loading={loading} ingest={ingest} />
        </React.Fragment>
    );
}

CameraSettings.propTypes = {
    camera: PropTypes.object.isRequired,
    playerConfig: PropTypes.object.isRequired,
};

CameraSettings.defaultProps = {};

export default CameraSettings;
