import * as React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import UserRole from "./UserRole";
import UserAvatarUpload from "./UserAvatarUpload";

import { useApp } from "../../context/App";

function UserEdit({
    user,
    save,
    setSave,
    reset,
    setReset,
    formValuesChanged,
    setFormValuesChanged,
}) {
    const { client } = useApp();
    const [userData, setUserData] = React.useState(null);
    const [uploadedFile, setUploadedFile] = React.useState(null);
    const [formValues, setFormValues] = React.useState({
        avatar: null,
        first_name: null,
        last_name: null,
        company: null,
        role: "",
        description: null,
        email_opt_in: false,
        phone: null,
        phone_opt_in: false,
        mobile: null,
        mobile_opt_in: false,
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === "checkbox" ? checked : value;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: newValue,
        }));
        setFormValuesChanged(true);
    };

    const getUserData = async () => {
        const data = await client.GetUser({ email: user.email });
        if (data) {
            setUserData(data);
            setFormValues({
                first_name: data.first_name,
                last_name: data.last_name,
                role: data.role,
                company: data.company,
                description: data.description,
                avatar_url: data.avatar_url,
                email_opt_in: data.email_opt_in,
                phone: data.phone,
                phone_opt_in: data.phone_opt_in,
                mobile: data.mobile,
                mobile_opt_in: data.mobile_opt_in,
            });
            setUploadedFile(data.avatar_url);
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            if (save) {
                setSave(false);
                if (userData) {
                    const payload = {
                        first_name: formValues.first_name,
                        last_name: formValues.last_name,
                        role: formValues.role,
                        company: formValues.company,
                        description: formValues.description,
                        email_opt_in: formValues.email_opt_in,
                        phone: formValues.phone,
                        phone_opt_in: formValues.phone_opt_in,
                        mobile: formValues.mobile,
                        mobile_opt_in: formValues.mobile_opt_in,
                    };
                    try {
                        let user_update = await client.PutUser({
                            data: payload,
                        });
                        if (uploadedFile !== formValues.avatar_url) {
                            user_update = await client.PutUserAvatar({
                                image: uploadedFile,
                            });
                        }
                        if (user_update) {
                            await client.UserConfigUpdate(user_update);
                        }
                        setFormValuesChanged(false);
                    } catch (error) {
                        setFormValuesChanged(true);
                    }
                }
            }
        };
        fetchData();
        // eslint-disable-next-line
    }, [save, setSave]);

    React.useEffect(() => {
        if (uploadedFile !== formValues.avatar_url) {
            setFormValuesChanged(true);
        }
        // eslint-disable-next-line
    }, [uploadedFile, formValues]);

    React.useEffect(() => {
        if (reset) {
            setReset(false);
            setUserData(null);
            setFormValuesChanged(false);
        }
        // eslint-disable-next-line
    }, [reset, setReset]);

    React.useEffect(() => {
        if (!userData) {
            getUserData();
        }
        // eslint-disable-next-line
    }, [formValuesChanged]);

    React.useEffect(() => {
        if (!userData) {
            getUserData();
        }
        // eslint-disable-next-line
    }, [userData]);

    return (
        <Grid
            container
            justifyContent="flex-start"
            marginTop={1}
            marginBottom={2}
            spacing={1.5}
        >
            <Grid item xs={12}>
                <Paper
                    elevation={2}
                    sx={{
                        borderRadius: 2,
                        padding: 1,
                        p: "0px 0px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        height: 100,
                        width: 100,
                    }}
                >
                    <UserAvatarUpload
                        id="avatar"
                        uploadedFile={uploadedFile}
                        setUploadedFile={setUploadedFile}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    type="text"
                    autoComplete="ls-profile given-name"
                    label="Vorname"
                    name="first_name"
                    value={formValues.first_name || ""}
                    onChange={handleChange}
                    autoFocus
                    fullWidth
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    type="text"
                    autoComplete="ls-profile family-name"
                    label="Nachname"
                    name="last_name"
                    value={formValues.last_name || ""}
                    onChange={handleChange}
                    autoFocus
                    fullWidth
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    label="Firma"
                    name="company"
                    value={formValues.company || ""}
                    onChange={handleChange}
                    fullWidth
                    autoFocus
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <UserRole
                    value={formValues.role || ""}
                    handleChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    label="Zusätzliche Beschreibung"
                    name="description"
                    value={formValues.description || ""}
                    onChange={handleChange}
                    fullWidth
                    autoFocus
                    size="small"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={9}>
                <TextField
                    autoFocus
                    size="small"
                    name="email"
                    readOnly
                    label="E-Mail Adresse"
                    type="email"
                    fullWidth
                    variant="standard"
                    value={user.email}
                    error={user.email && !formValues.email_opt_in}
                    helperText={
                        user.email && !formValues.email_opt_in
                            ? "Bitte den E-Mail Opt-In bestätigen."
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={3}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height={"100%"}
                >
                    <FormGroup>
                        <FormControlLabel
                            label="Opt-In"
                            control={
                                <Checkbox
                                    color="success"
                                    name="email_opt_in"
                                    checked={formValues.email_opt_in}
                                    onChange={handleChange}
                                />
                            }
                        />
                    </FormGroup>
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    autoFocus
                    size="small"
                    name="phone"
                    readOnly
                    label="Telefonnummer"
                    type="phone"
                    fullWidth
                    variant="standard"
                    value={formValues.phone || ""}
                    onChange={handleChange}
                    error={formValues.phone && !formValues.phone_opt_in}
                    helperText={
                        formValues.phone && !formValues.phone_opt_in
                            ? "Bitte den Telefon Opt-In bestätigen."
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={3}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height={"100%"}
                >
                    <FormGroup>
                        <FormControlLabel
                            label="Opt-In"
                            disabled={
                                !formValues.phone ||
                                formValues.phone.length === 0
                            }
                            control={
                                <Checkbox
                                    color="success"
                                    name="phone_opt_in"
                                    checked={formValues.phone_opt_in}
                                    onChange={handleChange}
                                />
                            }
                        />
                    </FormGroup>
                </Stack>
            </Grid>
            <Grid item xs={9}>
                <TextField
                    autoFocus
                    size="small"
                    name="mobile"
                    readOnly
                    label="Handynummer"
                    type="mobile"
                    fullWidth
                    variant="standard"
                    value={formValues.mobile || ""}
                    onChange={handleChange}
                    error={formValues.mobile && !formValues.mobile_opt_in}
                    helperText={
                        formValues.mobile && !formValues.mobile_opt_in
                            ? "Bitte den Handy Opt-In bestätigen."
                            : ""
                    }
                />
            </Grid>
            <Grid item xs={3}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    height={"100%"}
                >
                    <FormGroup>
                        <FormControlLabel
                            label="Opt-In"
                            disabled={
                                !formValues.mobile ||
                                formValues.mobile.length === 0
                            }
                            control={
                                <Checkbox
                                    color="success"
                                    name="mobile_opt_in"
                                    checked={formValues.mobile_opt_in}
                                    onChange={handleChange}
                                />
                            }
                        />
                    </FormGroup>
                </Stack>
            </Grid>
        </Grid>
    );
}

UserEdit.propTypes = {
    user: PropTypes.object.isRequired,
    save: PropTypes.bool.isRequired,
    setSave: PropTypes.func.isRequired,
    reset: PropTypes.bool.isRequired,
    setReset: PropTypes.func.isRequired,
    formValuesChanged: PropTypes.bool.isRequired,
    setFormValuesChanged: PropTypes.func.isRequired,
};

UserEdit.defaultProps = {};

export default UserEdit;
