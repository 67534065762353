import * as React from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircularProgress from "@mui/material/CircularProgress";

import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";

import { useApp } from "../../../../context/App";

function User({ first_name, last_name }) {
    return (
        <Chip
            Typographyvariant="outlined"
            icon={<AccountCircleIcon color="#fff" />}
            style={{ textTransform: "initial", fontWeight: 500, margin: 3 }}
            label={`${first_name} ${last_name}`}
        />
    );
}
User.propTypes = {
    first_name: PropTypes.string,
    last_name: PropTypes.string,
};
User.defaultProps = {};

function PlaybackPlayer({ name }) {
    return (
        <Chip
            Typographyvariant="outlined"
            icon={<PlayCircleIcon color="#fff" />}
            style={{ textTransform: "initial", fontWeight: 500, margin: 3 }}
            label={`${name}`}
        />
    );
}
PlaybackPlayer.propTypes = {
    name: PropTypes.string,
};
PlaybackPlayer.defaultProps = {};

function Linklist({ player, user }) {
    return (
        <React.Fragment>
            <Grid
                container
                justifyContent="space-between"
                fontSize={14}
                spacing={2}
            >
                <Grid item xs={12} marginBottom={-1}>
                    <Typography variant="h6">Zugänge:</Typography>
                </Grid>
                <Grid item xs={12}>
                    {user.map((u) => (
                        <User
                            key={u.id}
                            first_name={u.first_name}
                            last_name={u.last_name}
                        />
                    ))}
                </Grid>
                <Grid item xs={12} marginBottom={-1}>
                    <Typography variant="h6">Player:</Typography>
                </Grid>
                <Grid item xs={12}>
                    {player.map((p) => (
                        <PlaybackPlayer key={p.id} name={p.name} />
                    ))}
                </Grid>
                <Grid item xs={12} marginBottom={-1}>
                    <Typography variant="h6">Direktzugriffe:</Typography>
                </Grid>
                <Grid item xs={12}>
                    {player.map((p) => (
                        <PlaybackPlayer key={p.id} name={p.name} />
                    ))}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
Linklist.propTypes = {
    player: PropTypes.array,
    user: PropTypes.array,
};
Linklist.defaultProps = {};

function AdminACLLinkSelect({ acl }) {
    const { client } = useApp();
    const [link, setLink] = React.useState(null);
    const loading = !link;

    React.useEffect(() => {
        if (!loading) {
            return undefined;
        }

        (async () => {
            if (!link) {
                client
                    .GetCustomerACLIdLink({ acl_id: acl.id })
                    .then((data) => setLink(data));
            }
        })();
        // eslint-disable-next-line
    }, [link]);

    return (
        <span>
            {loading ? (
                <CircularProgress color="inherit" size={20} />
            ) : (
                <Linklist user={link.user} player={link.player} playback_user={link.playback_user} />
            )}
        </span>
    );
}

AdminACLLinkSelect.propTypes = {
    acl: PropTypes.object,
};

AdminACLLinkSelect.defaultProps = {
    acl: null,
};

export default AdminACLLinkSelect;
