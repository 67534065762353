import * as React from "react";

import PropTypes from "prop-types";

import Page from "../../components/Page";

import AdminCompanyProfile from "./AdminCompany/AdminCompanyProfile";

import { useApp } from "../../context/App";

function Admin(props) {
    const { rootPath } = props;
    const { client, hasPermission, backdrop, setSelectedPage, reload } = useApp();
    const [ready, setReady] = React.useState(false);

    // PERMISSION CHECK
    const adminRoles = ["admin"];
    const billingRoles = adminRoles.concat(["billing"]);

    const [customer, setCustomer] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            setSelectedPage("admin_profile");
            await client.GetCustomer().then((data) => setCustomer(data));
            setReady(true);
            return () => {
                setReady(false);
            };
        })();
    }, [setSelectedPage, client, reload]);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    return (
        <Page
            drawerWidth={550}
            pageTitel="Profil"
			breadcrumbs={[
                {title: 'Admin', link: '#'},
                {title: 'Profil', link: rootPath}
            ]}
        >
            {hasPermission(billingRoles) && customer && (
                <AdminCompanyProfile customer={customer} />
            )}
        </Page>
    );
}

Admin.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

export default Admin;
