import React, { useState, useRef, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';

function SearchField({
    searchText = "",
    setSearchText = () => { },
    autoComplete = false,
    autoCompleteOptions = [],
    outlined = false
}) {

    const [isActive, setIsActive] = useState(false);
    const textFieldRef = useRef(null);

    const handleChangeSearch = (event) => {
        setSearchText(event.target.value);
    };

    const handleActivateSearch = () => {
        setIsActive(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (textFieldRef.current && !textFieldRef.current.contains(event.target) && (searchText === '' || searchText === null)) {
                setIsActive(false);
            }
        };

        if (isActive) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        
    }, [isActive, searchText]);

    if (!isActive) {
        return (
            <IconButton 
                onClick={handleActivateSearch}
                sx={{ 
                    padding: 0,
                    height: 41,
                    width: 45,
                    minWidth: 40,
                    boxShadow: outlined ? 1 : 0, 
                    borderRadius: 1, 
                    background: "#fff", 
                    border: outlined ? "1px solid rgba(0,0,0,.2)" : "none"
                }}
            >
                <SearchIcon sx={{marginTop: .2}} />
            </IconButton>
        );
    }

    const onInputChange = (event, value, reason) => {
		if (event && event.type === 'blur') {
			setSearchText('');
		} else if (reason !== 'reset') {
			setSearchText(value);
		}
	};

    if (autoComplete) {
        return (
            <Autocomplete
                ref={textFieldRef}
                size="small"
                options={autoCompleteOptions ? autoCompleteOptions : []}
                onInputChange={onInputChange}
                clearOnBlur={false}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                onChange={(e, v) => {
                    setSearchText(v ? v.title : null);
                }}
                sx={{
                    width: "100%",
                    maxWidth: 450,
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        name="search"
                        label=""
                        color="primary"
                        variant="outlined"
                        placeholder="Suche..."
                        autoFocus
                        sx={{
                            boxShadow: 1,
                            borderRadius: 1,
                            background: '#fff',
                            border: '1px solid rgba(0,0,0,.2)',
                            margin: 0,
                            height: 41,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {params.InputProps.endAdornment}
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
                noOptionsText={'Kein Ergebnis gefunden'}
                fullWidth
            />
        );
    } else {
        return (
            <TextField
                ref={textFieldRef}
                variant="outlined"
                size="small"
                value={searchText}
                onChange={handleChangeSearch}
                autoFocus
                placeholder="Suche..."
                sx={{
                    border: "1px solid rgba(0,0,0,0.1)",
                    borderRadius: "4px",
                    height: 41,
                    width: "100%",
                    maxWidth: 450,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
}

export default SearchField;