import countries from "i18n-iso-countries";
import iso3166 from "iso-3166-2";
countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

function get_color(colorIndex) {
    const palettes = [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#3F51B5",
        "#03A9F4",
        "#4CAF50",
        "#F9CE1D",
        "#FF9800",
        "#33B2DF",
        "#546E7A",
        "#D4526E",
        "#13D8AA",
        "#A5978B",
        "#4ECDC4",
        "#C7F464",
        "#81D4FA",
        "#546E7A",
        "#FD6A6A",
        "#2B908F",
        "#F9A3A4",
        "#90EE7E",
        "#FA4443",
        "#69D2E7",
        "#449DD1",
        "#F86624",
        "#EA3546",
        "#662E9B",
        "#C5D86D",
        "#D7263D",
        "#1B998B",
        "#2E294E",
        "#F46036",
        "#E2C044",
        "#662E9B",
        "#F86624",
        "#F9C80E",
        "#EA3546",
        "#43BCCD",
        "#5C4742",
        "#A5978B",
        "#8D5B4C",
        "#5A2A27",
        "#C4BBAF",
        "#A300D6",
        "#7D02EB",
        "#5653FE",
        "#2983FF",
        "#00B1F2",
    ];
    let index = colorIndex % palettes.length;
    return palettes[index];
}

function get_country_name(country) {
    const unknown = "Unbekannt";
    if (country) {
        const countryName = countries.getName(country, "de");
        return { name: countryName ? countryName : unknown };
    }
    return { name: unknown };
}

function get_region_name(region) {
    // region = country + '-' + region;
    // const countryInfo = iso3166.country(metric.metric.country_iso)
    // const country = countryInfo && countryInfo.name ? countryInfo.name : "Unbekannt"
    // const regionInfo = countryInfo && countryInfo.name ? countryInfo.sub[metric.metric.country_iso + '-' + metric.metric.region_iso] : null;
    // const region = regionInfo && regionInfo.name ? regionInfo.name : "Unbekannt";
    // const name = country + ', ' + region;
    const unknown = "Unbekannt";
    if (region) {
        const regionInfo = iso3166.subdivision(region);
        const name = regionInfo && regionInfo.name ? regionInfo.name : unknown;
        return { name: name };
    }
    return { name: unknown };
}

function get_camera_meta(camera_id, cameras) {
    const camera = cameras.find((c) => c.id === camera_id);
    return camera ? camera.meta : { name: camera_id };
}

function get_player_meta(player_id, players) {
    if (player_id && player_id === "00000000-0000-0000-0000-000000000000") {
        return { name: "cm.livespotting.com" };
    } else {
        const player = players.find((p) => p.id === player_id);
        return player
            ? { name: player.name, domain_whitelist: player.domain_whitelist }
            : { name: player_id };
    }
}

function get_user_meta(user_email, users) {
    if (user_email && user_email.includes("livespotting")) {
        if (user_email === "s.erbeck@livespotting.com") {
            return { name: "Sven Erbeck (livespotting.com)" };
        } else if (user_email === "j.stabenow@livespotting.com") {
            return { name: "Jan Stabenow (livespotting.com)" };
        } else if (user_email === "i.oppermann@livespotting.com") {
            return { name: "Ingo Oppermann (livespotting.com)" };
        } else {
            return { name: user_email };
        }
    } else {
        const user = users.find((u) => u.username === user_email);
        return user
            ? { name: `${user.first_name} ${user.last_name} (${user.company})` }
            : { name: user_email };
    }
}

function get_playback_user_meta(playback_user_id, playback_users) {
    const playback_user = playback_users.find((u) => u.id === playback_user_id);
    return playback_user
        ? { name: playback_user.name }
        : { name: playback_user_id };
}

function get_device_type_name(device_type) {
    let name = "Unbekannt";
    if (device_type) {
        if (device_type === "Desktop") {
            name = "Desktop";
        } else if (device_type === "Mobile") {
            name = "Mobile";
        } else if (device_type === "TV") {
            name = "TV";
        } else if (device_type === "Tablet") {
            name = "Tablet";
        }
    }
    return { name: name };
}

function get_content_type_name(content_type) {
    let name = "Unbekannt";
    if (content_type) {
        if (content_type === "m3u8") {
            name = "Livestream";
        } else if (content_type === "jpg") {
            name = "Vorschaubild";
        } else if (content_type === "mp4") {
            name = "Videodatei";
        }
    }
    return { name: name };
}

function get_os_family_name(os_family) {
    if (!os_family) {
        os_family = "Unbekannt";
    }
    return { name: `${os_family}` };
}

function get_os_version_name(os_family, os_version) {
    if (!os_family) {
        os_family = "Unbekannt";
    }
    if (!os_version) {
        os_version = "";
    }
    const subName = os_version ? ` ${os_version}` : "";
    return { name: `${os_family}${subName}` };
}

function get_browser_family_name( browser_family) {
    if (!browser_family) {
        browser_family = "Unbekannt";
    }
    return { name: `${browser_family}` };
}

function get_browser_version_name( browser_family, browser_version ) {
    if (!browser_family) {
        browser_family = "Unbekannt";
    }
    if (!browser_version) {
        browser_version = "";
    }
    const subName = browser_version ? ` ${browser_version}` : "";
    return { name: `${browser_family}${subName}` };
}

function get_id(dimension1, metric) {
    let id = "";
    if (dimension1 === "country_iso") {
        id = metric.country_iso;
    } else if (dimension1 === "region_iso") {
        id = `${metric.country_iso}-${metric.region_iso}`;
    } else if (dimension1 === "camera_id") {
        id = metric.camera_id;
    } else if (dimension1 === "player_id") {
        id = metric.player_id;
    } else if (dimension1 === "device_type") {
        id = metric.device_type;
    } else if (dimension1 === "content_type") {
        id = metric.content_type;
    } else if (dimension1 === "user_email") {
        id = metric.user_email;
    } else if (dimension1 === "playback_user_id") {
        id = metric.playback_user_id;
    } else if (dimension1 === "os_family") {
        id = `${metric.os_family}`;
    } else if (dimension1 === "os_version") {
        id = `${metric.os_family}-${metric.os_version}`;
    } else if (dimension1 === "browser_family") {
        id = `${metric.browser_family}`;
    } else if (dimension1 === "browser_version") {
        id = `${metric.browser_family}-${metric.browser_version}`;
    } else {
        id = dimension1;
    }
    return id;
}

function AnalyticsPrepareMetrics(dimension1, metrics, assets) {
    const rows = [];
    if (!metrics?.metrics?.length) {
        return [];
    }
    let colorIndex = 0;
    metrics.metrics.forEach((metric) => {
        const color = get_color(colorIndex);
        colorIndex += 1;
        const id = get_id(dimension1, metric);
        let row = {
            ...metric,
            id: id,
            session_hits: 0,
            session_hits_avg: 0,
            session_seconds: 0,
            session_seconds_avg: 0,
            //session_parallel: 0,
            color: color,
        };

        metric.values.forEach((value) => {
            row.session_hits += value.session_hits;
            row.session_seconds += value.session_seconds;
            // row.session_parallel = Math.max(
            //     value.session_parallel,
            //     row.session_parallel || 0,
            // );
            value.session_seconds_avg =
                value.session_seconds / value.session_hits;
        });

        row.session_hits_avg = parseInt(row.session_hits / metric.values.length);
        row.session_seconds_avg = row.session_seconds / row.session_hits;

        metric.values.forEach((value) => {
            value.session_hits_avg = parseInt(row.session_hits_avg);
        });

        let metadata = {};
        if (dimension1 === "country_iso") {
            metadata = get_country_name(metric.country_iso);
        } else if (dimension1 === "region_iso") {
            const country = get_country_name(metric.country_iso);
            metadata.country_name = country.name;
            const region = get_region_name(metric.country_iso + '-' + metric.region_iso);
            metadata.region_name = region.name;
            metadata.name = metadata.country_name + ', ' + metadata.region_name;
        } else if (dimension1 === "camera_id") {
            metadata = get_camera_meta(metric[dimension1], assets.cameras);
        } else if (dimension1 === "player_id") {
            metadata = get_player_meta(metric[dimension1], assets.players);
        } else if (dimension1 === "device_type") {
            metadata = get_device_type_name(metric[dimension1]);
        } else if (dimension1 === "content_type") {
            metadata = get_content_type_name(metric[dimension1]);
        } else if (dimension1 === "user_email") {
            metadata = get_user_meta(metric[dimension1], assets.users);
        } else if (dimension1 === "playback_user_id") {
            metadata = get_playback_user_meta(
                metric.playback_user_id,
                assets.playback_users,
            );
        } else if (dimension1 === "os_family") {
            metadata = get_os_family_name(metric.os_family);
        } else if (dimension1 === "os_version") {
            metadata = get_os_version_name(metric.os_family, metric.os_version);
        } else if (dimension1 === "browser_family") {
            metadata = get_browser_family_name(metric.browser_family);
        } else if (dimension1 === "browser_version") {
            metadata = get_browser_version_name(metric.browser_family, metric.browser_version);
        } else {
            metadata = { name: metric[dimension1] };
        }
        row = { ...row, ...metadata };

        rows.push(row);
    });
    return rows;
}

export default AnalyticsPrepareMetrics;
