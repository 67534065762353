import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useApp } from '../../../context/App';

import { RestreamEditForm } from './RestreamEdit';

function EditDialog({ client, restream, openEditDialog, handleCloseEditDialog, reloadPage }) {
	return (
		<Dialog open={openEditDialog} onClose={handleCloseEditDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{'Restream bearbeiten'}</DialogTitle>
			<DialogContent>
				<RestreamEditForm client={client} reloadPage={reloadPage} restream={restream} handleClose={handleCloseEditDialog} />
			</DialogContent>
		</Dialog>
	);
}

EditDialog.propTypes = {
	client: PropTypes.object.isRequired,
	restream: PropTypes.object.isRequired,
	openEditDialog: PropTypes.bool.isRequired,
	handleCloseEditDialog: PropTypes.func.isRequired,
	reloadPage: PropTypes.func.isRequired,
};

function EnableDialog({ client, restream, openEnableDialog, handleCloseEnableDialog, reloadPage }) {
	const enableRestream = async () => {
		await client.PutCustomerRestreamId({
			restream_id: restream.id,
			restream: { enabled: true },
		});
		reloadPage();
		handleCloseEnableDialog();
	};

	return (
		<Dialog open={openEnableDialog} onClose={handleCloseEnableDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{'Restream aktivieren'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Möchten den Restream <strong>{restream.name}</strong> aktivieren?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="default" color="warning" onClick={handleCloseEnableDialog}>
					Nein
				</Button>
				<Button variant="contained" color="secondary" onClick={enableRestream} autoFocus>
					Ja
				</Button>
			</DialogActions>
		</Dialog>
	);
}

EnableDialog.propTypes = {
	client: PropTypes.object.isRequired,
	restream: PropTypes.object.isRequired,
	openEnableDialog: PropTypes.bool.isRequired,
	handleCloseEnableDialog: PropTypes.func.isRequired,
	reloadPage: PropTypes.func.isRequired,
};

function DisableDialog({ client, restream, openDisableDialog, handleCloseDisableDialog, reloadPage }) {
	const disableRestream = async () => {
		await client.PutCustomerRestreamId({
			restream_id: restream.id,
			restream: { enabled: false },
		});
		reloadPage();
		handleCloseDisableDialog();
	};

	return (
		<Dialog open={openDisableDialog} onClose={handleCloseDisableDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{'Zugang sperren'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Möchten den Restream <strong>{restream.name}</strong> wirklich deaktivieren?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="default" color="warning" onClick={handleCloseDisableDialog}>
					Nein
				</Button>
				<Button variant="contained" color="secondary" onClick={disableRestream} autoFocus>
					Ja
				</Button>
			</DialogActions>
		</Dialog>
	);
}

DisableDialog.propTypes = {
	client: PropTypes.object.isRequired,
	restream: PropTypes.object.isRequired,
	openDisableDialog: PropTypes.bool.isRequired,
	handleCloseDisableDialog: PropTypes.func.isRequired,
	reloadPage: PropTypes.func.isRequired,
};

DisableDialog.defaultProps = {};

function DeleteDialog({ client, restream, openDeleteDialog, handleCloseDeleteDialog, reloadPage }) {
	const deleteRestream = async () => {
		await client.DeleteCustomerRestreamId({
			restream_id: restream.id,
		});
		reloadPage();
		handleCloseDeleteDialog();
	};

	return (
		<Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{'Zugang löschen'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Möchten den Restream <strong>{restream.name}</strong> wirklich löschen?
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="default" color="warning" onClick={handleCloseDeleteDialog}>
					Nein
				</Button>
				<Button variant="contained" color="secondary" onClick={deleteRestream} autoFocus>
					Ja
				</Button>
			</DialogActions>
		</Dialog>
	);
}

DeleteDialog.propTypes = {
	client: PropTypes.object.isRequired,
	restream: PropTypes.object.isRequired,
	openDeleteDialog: PropTypes.bool.isRequired,
	handleCloseDeleteDialog: PropTypes.func.isRequired,
	reloadPage: PropTypes.func.isRequired,
};

DeleteDialog.defaultProps = {};

function RestreamSidebarMenu({ restream, adminRoles, editorRoles }) {
	const { client, hasPermission, reloadPage } = useApp();
	const [anchorEl, setAnchorEl] = React.useState(false);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// Edit Dialog
	const [openEditDialog, setOpenEditDialog] = React.useState(false);
	const handleClickOpenEditDialog = () => {
		setOpenEditDialog(true);
	};
	const handleCloseEditDialog = () => {
		setOpenEditDialog(false);
	};

	// Enable Dialog
	const [openEnableDialog, setOpenEnableDialog] = React.useState(false);
	const handleCloseEnableDialog = () => {
		setOpenEnableDialog(false);
	};

	// Disable Dialog
	const [openDisableDialog, setOpenDisableDialog] = React.useState(false);
	const handleCloseDisableDialog = () => {
		setOpenDisableDialog(false);
	};

	// Delete Dialog
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
	const handleClickOpenDeleteDialog = () => {
		setOpenDeleteDialog(true);
	};
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	if (!restream) {
		return;
	}

	return (
		<div style={{ marginRight: 15 }}>
			<IconButton
				color="primary"
				id="message-menu-button"
				aria-controls={open ? 'message-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id="message-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'message-menu-button',
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<MenuList>
					<MenuItem component={Link} to={`/live/camera/${restream.camera_id}`}>
						<ListItemIcon>
							<OpenInNewIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Kamera anzeigen</ListItemText>
					</MenuItem>
					<Divider style={{ borderTop: 1 }} />
					{/* {restream.enabled && (
                        <MenuItem
                            onClick={handleClickOpenDisableDialog}
                            disabled={!hasPermission(editorRoles)}
                        >
                            <ListItemIcon>
                                <LockIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Deaktivieren</ListItemText>
                        </MenuItem>
                    )}
                    {!restream.enabled && (
                        <MenuItem
                            onClick={handleClickOpenEnableDialog}
                            disabled={!hasPermission(editorRoles)}
                        >
                            <ListItemIcon>
                                <LockIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Aktivieren</ListItemText>
                        </MenuItem>
                    )}
                    <Divider style={{ borderTop: 1 }} /> */}
					<MenuItem onClick={handleClickOpenEditDialog} disabled={!hasPermission(editorRoles)}>
						<ListItemIcon>
							<ModeEditIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Bearbeiten</ListItemText>
					</MenuItem>
					<MenuItem onClick={handleClickOpenDeleteDialog} disabled={!hasPermission(adminRoles)}>
						<ListItemIcon>
							<DeleteIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Löschen</ListItemText>
					</MenuItem>
				</MenuList>
			</Menu>
			<EditDialog
				client={client}
				restream={restream}
				openEditDialog={openEditDialog}
				handleCloseEditDialog={handleCloseEditDialog}
				reloadPage={reloadPage}
			/>
			{restream.enabled && (
				<DisableDialog
					client={client}
					restream={restream}
					openDisableDialog={openDisableDialog}
					handleCloseDisableDialog={handleCloseDisableDialog}
					reloadPage={reloadPage}
				/>
			)}
			{!restream.enabled && (
				<EnableDialog
					client={client}
					restream={restream}
					openEnableDialog={openEnableDialog}
					handleCloseEnableDialog={handleCloseEnableDialog}
					reloadPage={reloadPage}
				/>
			)}
			<DeleteDialog
				client={client}
				restream={restream}
				openDeleteDialog={openDeleteDialog}
				handleCloseDeleteDialog={handleCloseDeleteDialog}
				reloadPage={reloadPage}
			/>
		</div>
	);
}

RestreamSidebarMenu.propTypes = {
	restream: PropTypes.object.isRequired,
	adminRoles: PropTypes.array,
	editorRoles: PropTypes.array,
};

RestreamSidebarMenu.defaultProps = {
	adminRoles: [],
	editorRoles: [],
};

export default RestreamSidebarMenu;
