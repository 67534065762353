/* eslint-disable import/no-anonymous-default-export */

export default {
    styleOverrides: {
        root: {
            borderBottom: `1px solid rgba(255,255,255, .2)`,
            "&.Mui-selected": {
                color: "#fff",
                backgroundColor: "#0084E8",
            },
            '&[aria-selected="true"]': {
                color: "#fff",
                backgroundColor: "#0084E8",
            },
            "&.Mui-selected:hover": {
                color: "#fff",
                backgroundColor: "rgba(0,132,232,.9)",
            },
            "&:hover": {
                color: "#fff",
                backgroundColor: "rgba(0,132,232,.9)",
            },
        },
    },
};
