import * as React from "react";
import { Route, Navigate } from "react-router-dom";

import Stack from "@mui/material/Stack";

import Login from "./views/Login";
import LoginTwoFactor from "./views/LoginTwoFactor";
import Wizard from "./views/Wizard";
import Dashboard from "./views/Dashboard";
import AlertCamera from "./views/Alert/camera";
import Camera from "./views/Camera";
// import Playout from "./views/Playout";
import VideoCamera from "./views/Video/camera";
import Restream from "./views/Restream";
import PlaybackPlayer from "./views/Playback/player";
import PlaybackUser from "./views/Playback/user";
import AnalyticsCamera from "./views/Analytics/camera";
import AnalyticsPlaybackPlayer from "./views/Analytics/playbackPlayer";
import AnalyticsPlaybackUser from "./views/Analytics/playbackUser";
import AnalyticsUser from "./views/Analytics/user";
// import Ticket from "./views/Ticket";
import AdminProfile from "./views/Admin/profile";
import AdminUser from "./views/Admin/user";
import AdminACL from "./views/Admin/acl";
import AdminProtocol from "./views/Admin/protocol";
import AdminBilling from "./views/Admin/billing";

import AppPage from "./components/AppPage";
import AdminBar from "./components/AdminBar";
import PageMenu from "./components/PageMenu";
import PageUserMenu from "./components/PageUserMenu";
import PageSubMenu, { PageSubMenuAds } from "./components/PageSubMenu";

// grafana
import { FaroRoutes } from "@grafana/faro-react";

import { useApp } from "./context/App";


function App() {
    const { client, hasPermission, selectedPage, openMenu, handleMenuToggle } = useApp();
    const [hoveredPage, setHoveredPage] = React.useState(null);
    const [subMenuOpen, setSubMenuOpen] = React.useState(false);
    const handleSubmenuOpen = (pageName) => {
        setHoveredPage(pageName);
        setSubMenuOpen(true);
    };
    const handleSubmenuClose = () => {
        setSubMenuOpen(false);
        setHoveredPage(null);
    };

    const logout = async () => {
        await client.logout();
    };

    // Close mobile menu on page change
    const handleMenuClick = () => {
        if (openMenu) {
            handleMenuToggle();
        };
        setSubMenuOpen(false);
    };

    // PERMISSION CHECK
    const dashboardRoles = [
        "admin", 
        "editor", 
        "viewer",
        "analytics:viewer",
    ];
    const alertRoles = [
        "admin", 
        "editor", 
        "viewer",
        "camera:viewer",
        "camera:editor",
        "camera:admin",
    ];
    const cameraRoles = [
        "admin",
        "editor",
        "viewer",
        "camera:viewer",
        "camera:editor",
        "camera:admin",
    ];
    const playoutRoles = [
        "playout:viewer",
        "playout:editor",
        "playout:admin",
    ];
    const videoRoles = [
        "admin",
        "editor",
        "viewer",
        "video:viewer",
        "video:editor",
        "video:admin",
    ];
    const playbackPlayerRoles = [
        "admin",
        "editor",
        "viewer",
        "player:viewer",
        "player:editor",
        "player:admin",
    ];
    const playbackUserRoles = [
        "admin",
        "editor",
        "viewer",
        "player:viewer",
        "player:editor",
        "player:admin",
    ];
    const analyticsRoles = [
        "admin",
        "editor",
        "viewer",
        "analytics:viewer",
    ];
    const ticketRoles = [];
    const restreamRole = [
        "admin",
        "editor",
        "viewer",
        "restream:viewer",
        "restream:editor",
        "restream:admin",
    ];
    const adminRoles = ["admin"];

    const pagePermission = (pageName) => {
        if (pageName === "dashboard") {
            return hasPermission(dashboardRoles);
        } else if (pageName === "alert") {
            return hasPermission(alertRoles);
        } else if (pageName === "camera") {
            return hasPermission(cameraRoles);
        } else if (pageName === "playout") {
            return hasPermission(playoutRoles);
        } else if (pageName === "video") {
            return hasPermission(videoRoles);
        } else if (pageName === "playback_player") {
            return hasPermission(playbackPlayerRoles);
        } else if (pageName === "playback_user") {
            return hasPermission(playbackUserRoles);
        } else if (pageName === "ticket") {
            return hasPermission(ticketRoles);
        } else if (pageName === "restream") {
            return hasPermission(restreamRole);
        } else if (pageName === "admin") {
            return hasPermission(adminRoles);
        } else if (pageName === "analytics") {
            return hasPermission(analyticsRoles);
        } else {
            return false;
        }
    };

    if (!client.LoggedIn()) {
        let content = null;
        if (client.LoggedInTwoFactor()) {
            content = <LoginTwoFactor />;
        } else {
            content = <Login />;
        }

        return (
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                margin={1}
            >
                {content}
            </Stack>
        );
    }

    if (client.UserSetup()) {
        return (
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                margin={1}
            >
                <Wizard />;
            </Stack>
        );
    }

    let content = (
        <AppPage 
            pageMenu={
                <PageMenu 
                    selectedPage={selectedPage} 
                    handleMenuClick={handleMenuClick} 
                    pagePermission={pagePermission} 
                    handleSubmenuOpen={handleSubmenuOpen} 
                    handleSubmenuClose={handleSubmenuClose} 
                />
            } 
            userMenu={
                <PageUserMenu
                    logout={logout}
                />
            } 
            subMenu={
                <PageSubMenu
                    hoveredPage={hoveredPage}
                    selectedPage={selectedPage} 
                    handleMenuClick={handleMenuClick} 
                    pagePermission={pagePermission} 
                />
            }
            subMenuAd={
                <PageSubMenuAds 
                    hoveredPage={hoveredPage} 
                />
            } 
            subMenuOpen={subMenuOpen}
            setSubMenuOpen={setSubMenuOpen}
        >
            <FaroRoutes>
                {pagePermission("dashboard") && (
                    <React.Fragment>
                        <Route path="/" element={<Dashboard rootPath="/dashboard" />} />
                        <Route path="/dashboard" element={<Dashboard rootPath="/dashboard" />} />
                    </React.Fragment>
                )}
                {pagePermission("camera") && (
                    <React.Fragment>
                        <Route path="/live/camera" element={<Camera rootPath="/live/camera" />} />
                        <Route path="/live/camera/:camera_id" element={<Camera rootPath="/live/camera" />} />
                    </React.Fragment>
                )}
                {pagePermission("restream") && (
                    <React.Fragment>
                        <Route path="/live/restream" element={<Restream rootPath="/live/restream" />} />
                        <Route path="/live/restream/:restream_id" element={<Restream rootPath="/live/restream" />} />
                    </React.Fragment>
                )}
                {/* {pagePermission("playout") && (
                    <React.Fragment>
                        <Route path="/live/playout" element={<Playout rootPath="/live/playout" />} />
                        <Route path="/live/playout/:playout_id" element={<Playout rootPath="/live/playout" />} />
                    </React.Fragment>
                )} */}
                {pagePermission("video") && (
                    <React.Fragment>
                        <Route path="/video/camera" element={<VideoCamera rootPath="/video/camera" />} />
                        <Route path="/video/camera/:video_id" element={<VideoCamera rootPath="/video/camera" />} />
                    </React.Fragment>
                )}
                {pagePermission("playback_player") && (
                    <React.Fragment>
                        <Route path="/playback/player" element={<PlaybackPlayer rootPath="/playback/player" />} />
                        <Route
                            path="/playback/player/:player_id"
                            element={<PlaybackPlayer rootPath="/playback/player" />}
                        />
                    </React.Fragment>
                )}
                {pagePermission("playback_user") && (
                    <React.Fragment>
                        <Route path="/playback/user" element={<PlaybackUser rootPath="/playback/user" />} />
                        <Route
                            path="/playback/user/:user_id"
                            element={<PlaybackUser rootPath="/playback/user" />}
                        />
                    </React.Fragment>
                )}
                {/* {pagePermission("ticket") && (
                    <React.Fragment>
                        <Route path="/ticket" element={<Ticket rootPath="/ticket" />} />
                        <Route path="/ticket/:ticket_id" element={<Ticket rootPath="/ticket" />} />
                    </React.Fragment>
                )} */}
                {pagePermission("analytics") && (
                    <React.Fragment>
                        <Route path="/analytics/camera" element={<AnalyticsCamera rootPath="/analytics/camera" />} />
                        <Route path="/analytics/playback_player" element={<AnalyticsPlaybackPlayer rootPath="/analytics/playback_player" />} />
                        <Route path="/analytics/playback_user" element={<AnalyticsPlaybackUser rootPath="/analytics/playback_user" />} />
                        <Route path="/analytics/user" element={<AnalyticsUser rootPath="/analytics/user" />} />
                    </React.Fragment>
                )}
                {pagePermission("alert") && (
                    <React.Fragment>
                        <Route path="/alert/camera" element={<AlertCamera rootPath="/alert/camera" />} />
                        <Route path="/alert/camera/:camera_id" element={<AlertCamera rootPath="/alert/camera" />} />
                    </React.Fragment>
                )}
                {pagePermission("admin") && (
                    <React.Fragment>
                        <Route path="/admin/profile" element={<AdminProfile rootPath="/admin/profile" />} />
                        <Route path="/admin/user" element={<AdminUser rootPath="/admin/user" />} />
                        <Route path="/admin/user/:user_id" element={<AdminUser rootPath="/admin/user" />} />
                        <Route path="/admin/acl" element={<AdminACL rootPath="/admin/acl" />} />
                        <Route path="/admin/acl/:acl_id" element={<AdminACL rootPath="/admin/acl" />} />
                        <Route path="/admin/protocol" element={<AdminProtocol rootPath="/admin/protocol" />} />
                        <Route path="/admin/billing" element={<AdminBilling rootPath="/admin/billing" />} />
                    </React.Fragment>
                )}
                <Route path="/*" element={<Navigate to="/" replace />} />
            </FaroRoutes>
        </AppPage>
    );

    const user = client.UserConfig();

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
            margin={1}
        >
            {user.type === "superuser" && <AdminBar />}
            {content}
        </Stack>
    );
}

App.propTypes = {};

export default App;
