/* eslint-disable import/no-anonymous-default-export */
export default {
    styleOverrides: {
        root: {
            width: "100%",
            marginTop: "0.35em",
            marginBottom: "0.35em",
            border: 0,
            borderBottom: `2px solid rgba(0,0,0,.05)`,
            borderTop: `1px solid rgba(0,0,0,.15)`,
            
        },
    },
};
