import * as React from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import { useApp } from "../context/App";
import Logo from "../images/livespotting.svg";

function ServiceIcon(props) {
    return <img src={Logo} alt="livespotting.com Icon" {...props} />;
}

function AppPage({
    subMenuOpen = false,
    setSubMenuOpen = null,
    window = null,
    children = null,
    pageMenu = null,
    userMenu = null,
    subMenu = null,
    subMenuAd = null,
}) {
    const container =
        window !== null ? () => window().document.body : undefined;

    const { client, openMenu, handleMenuToggle } = useApp();
    const user = client.UserConfig();

    const drawerWidth = subMenuOpen ? 300 : 75;
    const handleMouseLeave = () => {
        setSubMenuOpen(false);
    };

    const menuContent = () => {
        return (
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                height={"100%"}
                width={drawerWidth}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    height={user.type === "superuser" ? "calc(100% - 55px)" : "100%"}
                    minWidth={75}
                    spacing={0}
                >
                    <ServiceIcon
                        style={{ width: "40px", marginTop: "25px", marginBottom: "25px" }}
                    />
                    <Divider sx={{width: "100%", border: "2px solid rgba(0,0,0,.1)", borderTop: "1px solid rgba(0,0,0,.2)"}} />
                    {pageMenu}
                    <Divider sx={{width: "100%", border: "2px solid rgba(0,0,0,.1)", borderTop: "1px solid rgba(0,0,0,.2)"}} />
                    {userMenu}
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                        border: "1px solid rgba(0,0,0,.2)",
                        borderRight: "2px solid rgba(0,0,0,.1)",
                    }}
                    height={user.type === "superuser" ? "calc(100% - 55px)" : "100%"}
                    width={'1px'}
                >
                </Stack>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    height={user.type === "superuser" ? "calc(100% - 55px)" : "100%"}
                    spacing={0}
                    sx={{
                        opacity: !subMenuOpen ? 0 : 1,
                    }}
                    width={"100%"}
                >
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        paddingTop={1}
                        paddingBottom={2}
                        paddingLeft={'18px'}
                        paddingRight={'18px'}
                        height={"100%"}
                        width={"100%"}
                    >
                        {subMenu}
                    </Stack>
                    {subMenuAd && (
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                            paddingTop={1}
                            paddingBottom={2}
                            paddingLeft={'18px'}
                            paddingRight={'18px'}
                            width={"100%"}
                        >
                            {subMenuAd}
                        </Stack>
                    )}
                </Stack>
            </Stack>
        );
    };

    return (
        <React.Fragment>
            <Box sx={{ display: "flex", paddingTop: "20px", width: "100%" }}>
                <CssBaseline />
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor="left"
                    open={openMenu}
                    onClose={handleMenuToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    xs={{
                        paper: {
                            backgroundColor: "#000",
                            overflowX: "hidden",
                        },
                    }}
                    sx={{
                        display: { xs: "block", sm: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            padding: 0,
                            width: drawerWidth,
                            top: user.type === "superuser" ? "55px" : null,
                            transition: "width 0.15s ease-in-out",
                        },
                    }}
                    onMouseLeave={handleMouseLeave}
                    PaperProps={{ sx: { overflow: "hidden" } }}
                    elevation={3}
                >
                    {menuContent()}
                </Drawer>
                <Drawer
                    variant="permanent"
                    anchor="left"
                    sx={{
                        display: { xs: "none", sm: "block" },
                        width: drawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                            flexShrink: 0,
                            top: user.type === "superuser" ? "55px" : null,
                            padding: 0,
                            transition: "width 0.15s ease-in-out",
                        },
                    }}
                    onMouseLeave={handleMouseLeave}
                    PaperProps={{ sx: { overflowX: "hidden" } }}
                    open
                >
                    {menuContent()}
                </Drawer>
                 <Box component="main" sx={{ flexGrow: 1, marginLeft: -2 }}>
                    {children}
                </Box>
            </Box>
        </React.Fragment>
    );
}

AppPage.propTypes = {
    window: PropTypes.func,
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
};

export default AppPage;
