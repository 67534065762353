import React from "react";

import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import CustomerButton from "./CustomerButton";
import UserButton from "./UserButton";


export default function PageUserMenu(props) {
    const { logout } = props;
    return (
        <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0.5}
            marginTop={2}
            marginBottom={1}
        >
            <UserButton />
            <CustomerButton />
            <IconButton
                aria-label="logout"
                size="large"
                onClick={logout}
                sx={{
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                    color: "#494747",
                    '& .MuiTouchRipple-root .MuiTouchRipple-child': {
                        borderRadius: "8px",
                    },
                }}
            >
                <ExitToAppIcon
                    fontSize="inherit"
                />
            </IconButton>
        </Stack>
    );
}