import * as React from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export default function X(props) {
	return {
		id: 'rtmp',
		verion: '1.0',
		name: 'RTMP-Server',
		description: 'Stream to custom rtmp service',
		category: null,
		help_link: null,
		icon: <CloudUploadIcon style={{ color: '#2D88FF', ...props }} />,
		stream_key_link: null,
		stream_requirements: {
			video_codec: ['h264'],
			audio_codec: ['aac'],
			video_resolution: ['1280x720', '1920x1080', '2560x1440', '3840x2160'],
			video_fps: {
				min: 15,
				max: 60,
			},
			video_bitrate: {
				min: 300,
				max: 24000,
			},
			audio_bitrate: {
				min: 64,
				max: 320,
			},
			audio_channels: null,
		},
		stream_targets: [
			{
				address: 'rtmp://',
				options: ['-f', 'flv'],
				type: 'primary',
				protocol: 'rtmp',
				region: 'international',
			},
		],
	};
}
