import * as React from "react";
import PropTypes from "prop-types";

import "moment/locale/de";
import "moment-timezone";
import moment from "moment";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import HistorySummaryBoxInfo from "./HistorySummaryBoxInfo";

function HistorySummaryBox({
    reportDateRange = null,
    report = {
        player_summary: {
            session_hits_total: 0,
            session_hits_total_28d_delta: 0,
            session_hits_total_28d_delta_percent: 0,
            session_seconds_total: 0,
            session_seconds_total_28d_delta: 0,
            session_seconds_total_28d_delta_percent: 0,
        }
    }, 
}) {
    
    if (!report) return (
        <>
            <Typography variant="h6" color="textSecondary" sx={{ color: "#0084E8", lineHeight: "1.2", fontWeight: 500 }}>
                Zusammenfassung
            </Typography>
        </>
    );

    const dateRange = {
        from_date: moment().hours(0).minutes(0).seconds(0).subtract(28, "days"),
        from_date_delta: moment().hours(0).minutes(0).seconds(0).subtract(56, "days"),
        to_date: moment().hours(0).minutes(0).seconds(0),
        to_date_delta: moment().hours(0).minutes(0).seconds(0).subtract(28, "days"),
    };

    // playback time calculation
    function CalcMetricMinutes(session_seconds, session_hits) {
        let totalMinutes =
            parseFloat(session_seconds / session_hits) / 60;
        let minutes = Math.floor(totalMinutes);
        let seconds = Math.round((totalMinutes - minutes) * 60);
        let timeString = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
        return timeString;
    }

    // hits
    const hits = report.player_summary.session_hits_total;
    const hitsDeltaPercent = report.player_summary.session_hits_total_28d_delta_percent;

    // hours
    const hours = Math.floor(report.player_summary.session_seconds_total / 60 / 60);
    const hoursDeltaPercent = report.player_summary.session_seconds_total_28d_delta_percent;

    // playback time
    const playMin = CalcMetricMinutes(report.player_summary.session_seconds_total, hits);
    const playSec = Math.floor(report.player_summary.session_seconds_total / hits);
    const playSecDelta = Math.floor(report.player_summary.session_seconds_total_28d_delta / report.player_summary.session_hits_total_28d_delta);
    const playMinDelta = parseFloat((((playSec - playSecDelta) / playSecDelta) * 100).toFixed(2));

    return (
        <>
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={0}
                marginTop={-.5}
            >
                <Typography variant="h6" color="textSecondary" sx={{ fontSize: 16, lineHeight: "1.2", paddingTop: 0, color: "#0084E8", fontWeight: 500 }}>
                    28 Tage Auswertung
                </Typography>
                <HistorySummaryBoxInfo dateRange={dateRange}/>
            </Stack>
            <Typography variant="h6" color="textSecondary" sx={{ lineHeight: "1.2", fontSize: 14, fontWeight: 400, marginTop: 1.4, marginBottom: -1 }}>
                Aufrufe ({hitsDeltaPercent > 0 && ("+")}{hitsDeltaPercent.toLocaleString("de-DE")}%)
            </Typography>
            <Typography variant="h2" color="textSecondary" sx={{ paddingTop: .5, fontWeight: 500 }}>
                {hits.toLocaleString("de-DE")}
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{lineHeight: "1.2",  fontSize: 14, fontWeight: 400, marginTop: 1.4, marginBottom: -1 }}>
                Wiedergabezeit (Stunden) ({hoursDeltaPercent > 0 && ("+")}{hoursDeltaPercent.toLocaleString("de-DE")}%)
            </Typography>
            <Typography variant="h2" color="textSecondary" sx={{ paddingTop: .5, fontWeight: 500 }}>
                {hours.toLocaleString("de-DE")}
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{lineHeight: "1.2",  fontSize: 14, fontWeight: 400, marginTop: 1.4, marginBottom: -1 }}>
                Durchschnittliche Wiedergabezeit (Minuten) ({playMinDelta > 0 && ("+")}{playMinDelta.toLocaleString("de-DE")}%)
            </Typography>
            <Typography variant="h2" color="textSecondary" sx={{ paddingTop: .5, fontWeight: 500 }}>
                {playMin}
            </Typography>
        </>
    );
}

HistorySummaryBox.propTypes = {
    report: PropTypes.object,
    reportDateRange: PropTypes.object,
};

export default HistorySummaryBox;
