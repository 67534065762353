import * as React from "react";
import PropTypes from "prop-types";

import "moment-timezone";
import "moment/locale/de";
import moment from 'moment';

import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import BlinkingIcon from "../../components/BlinkingIcon";


function LiveViewerBox({ 
    playerMetrics = [] 
}) {

    function LiveViewer() {
        if (!playerMetrics) return;

        let current = 0;
        let trend = 0;

        playerMetrics.forEach(item => {
            current += item?.summary?.current;
            if (item?.summary?.trend > 0) {
                trend += 1;
            } else if (item?.summary?.trend < 0) {
                trend -= 1;
            }
        });

        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Typography variant="h1" color="white" sx={{ fontWeight: 500, marginTop: 0, marginBottom: -.5, textAlign: 'left' }}>{current.toString()}</Typography>
                {trend > 0 && (<ArrowUpwardIcon sx={{ color: "white", width: 30, height: 30 }} />)}
                {trend < 0 && (<ArrowDownwardIcon sx={{ color: "white", width: 30, height: 30 }} />)}
            </Stack>
        );
    }

    function LiveGraph() {
        if (!playerMetrics) return;

        const aggregatedSessions = {};
        const tsData = [];

        playerMetrics.forEach(item => {
            item.values.forEach(value => {
                if (aggregatedSessions[value[0]]) {
                    aggregatedSessions[value[0]] += Number(value[1]);
                } else {
                    aggregatedSessions[value[0]] = Number(value[1]);
                }
                if (!tsData.includes(value[0])) {
                    tsData.push(Number(value[0]));
                };
            });
        });

        const aggregatedArray = Object.keys(aggregatedSessions)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .map(ts => aggregatedSessions[ts]);

        const format = 'HH:mm:ss';
        const formattedTsData = tsData
            .sort((a, b) => parseInt(a) - parseInt(b))
            .map(ts => moment(ts * 1000).format(format));
                
        return (
            <SparkLineChart
                plotType="bar"
                data={aggregatedArray}
                xAxis={{
                    scaleType: 'band',
                    data: formattedTsData,
                    valueFormatter: (value) => value.toLocaleString(),
                }}
                height={100}
                showHighlight={true}
                showTooltip={true}
                colors={['white']}
                margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
            />
        );
    }

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Typography variant="h6" color="textSecondary" sx={{ color: "#fff", fontWeight: 500 }}>
                    Aktuelle Zugriffe
                </Typography>
                <BlinkingIcon metric={playerMetrics} />
            </Stack>
            <LiveGraph />
            <LiveViewer />
        </>
    );
}

LiveViewerBox.propTypes = {
    playerMetrics: PropTypes.array,
};

export default LiveViewerBox;
