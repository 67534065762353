import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useApp } from "../context/App";
import PageHeader from "./PageHeader";
import PageSidebarHeader from "./PageSidebarHeader";

const Main = styled("main", {
    shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})(({ theme, open, user, downSm }) => ({
    flexGrow: 1,
    width: `100%`,
    padding: theme.spacing(downSm ? 1 : 5),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginTop: user?.type === "superuser" ? 0 : -20,
    ...(open && {
        width: `100%`,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

function Page(props) {
    const {
        drawerWidth = 450,
        openSidebar = false,
        handleSidebarToggle = null,
        sidebarFullScreen = false,
        handleSidebarFullscreenToggle = null,
        headerContentLeft = null,
        headerContentRight = null,
        pageTitel = null,
        sidebarTitel = null,
        sidebarContent = null,
        sidebarContentRight = null,
        breadcrumbs = [],
        window = undefined,
        children = null,
    } = props;
    // DISABLES MOBILE DRAWER >= MD (non-clickable bug)
    const theme = useTheme();
    const downSm = useMediaQuery(theme.breakpoints.down("sm"));
    const downMd = useMediaQuery(theme.breakpoints.down("md"));

    const container =
        window !== undefined ? () => window().document.body : undefined;

    const { client } = useApp();
    const user = client.UserConfig();

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            {downMd && (
                <Drawer
                    container={container}
                    variant="temporary"
                    ModalProps={{
                        keepMounted: true,
                    }}
                    anchor="right"
                    open={openSidebar}
                    onClose={handleSidebarToggle}
                    xs={{ paper: { backgroundColor: "#000" } }}
                    sx={{
                        display: {
                            xs: openSidebar ? "block" : "none",
                            sm: "none",
                        },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: "100%",
                            padding: 0,
                            top: user.type === "superuser" ? "55px" : null,
                        },
                    }}
                    elevation={0}
                >
                    <PageSidebarHeader
                        handleSidebarToggle={handleSidebarToggle}
                        sidebarFullScreen={sidebarFullScreen}
                        handleSidebarFullscreenToggle={
                            handleSidebarFullscreenToggle
                        }
                        sidebarContentRight={sidebarContentRight}
                    />
                    {sidebarTitel && (
                        <React.Fragment>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                                width={"100%"}
                                height={"24px"}
                                minHeight={"24px"}
                                maxHeight={"24px"}
                                marginTop={"23px"}
                                marginBottom={"15px"}
                                paddingLeft={4}
                            >
                                <LocationOnIcon style={{ color: "#0085E9" }} />
                                <Typography
                                    variant="h1"
                                    fontWeight={600}
                                    fontSize={"1.25rem"}
                                >
                                    {sidebarTitel}
                                </Typography>
                            </Stack>
                            <Divider />
                        </React.Fragment>
                    )}
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        width={"100%"}
                        padding={4}
                    >
                        {sidebarContent}
                    </Stack>
                </Drawer>
            )}
            <Main open={openSidebar} drawerWidth={drawerWidth} user={user} downSm={downSm}>
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={4}
                    marginLeft={2}
                    marginRight={0}
                    marginTop={2}
                >
                    <PageHeader 
                        contentLeft={headerContentLeft} 
                        contentRight={headerContentRight} 
                        pageTitel={pageTitel} 
                        breadcrumbs={breadcrumbs} 
                    />
                    <Divider />
                    {children}
                </Stack>
            </Main>
            <Drawer
                sx={{
                    width: sidebarFullScreen ? "100%" : drawerWidth,
                    flexShrink: 0,
                    display: {
                        xs: "none",
                        sm: openSidebar ? "block" : "none",
                    },
                    "& .MuiDrawer-paper": {
                        width: sidebarFullScreen
                            ? "100%"
                            : drawerWidth,
                        top: user.type === "superuser" ? "55px" : null,
                        padding: 0,
                    },
                }}
                variant={sidebarFullScreen ? "temporary" : "persistent"}
                container={sidebarFullScreen ? container : null}
                anchor="right"
                open={openSidebar}
                elevation={0}
                transitionDuration={0}
            >
                <PageSidebarHeader
                    handleSidebarToggle={handleSidebarToggle}
                    sidebarFullScreen={sidebarFullScreen}
                    handleSidebarFullscreenToggle={
                        handleSidebarFullscreenToggle
                    }
                    sidebarContentRight={sidebarContentRight}
                />
                <Divider sx={{width: "100%", marginBottom: 2, border: "2px solid rgba(0,0,0,.1)", borderTop: "1px solid rgba(0,0,0,.2)"}} />
                {sidebarTitel && (
                    <React.Fragment>
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={2}
                            width={"100%"}
                            padding={"15px 25px 15px 25px"}
                        >
                            <LocationOnIcon style={{ color: "#0085E9" }} />
                            <Typography
                                variant="h1"
                                fontWeight={600}
                                fontSize={"1.25rem"}
                            >
                                {sidebarTitel}
                            </Typography>
                        </Stack>
                        <Divider />
                    </React.Fragment>
                )}
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    width={"100%"}
                    marginTop={sidebarTitel ? null : -1}
                    paddingTop={sidebarTitel ? 2 : null}
                    marginBottom={user.type === "superuser" ? 5 : null}
                >
                    {sidebarContent}
                </Stack>
            </Drawer>
        </Box>
    );
}

Page.propTypes = {
    window: PropTypes.func,
    drawerWidth: PropTypes.number,
    openSidebar: PropTypes.bool,
    handleSidebarToggle: PropTypes.func,
    sidebarFullScreen: PropTypes.bool,
    handleSidebarFullscreenToggle: PropTypes.func,
    pageTitel: null,
    breadcrumbs: [],
};

export default Page;
