import * as React from 'react';
import PropTypes from 'prop-types';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { useApp } from '../../context/App';

import RestreamCameraSelect from './RestreamCameraSelect';
import RestreamCameraProfileSelect from './RestreamCameraProfileSelect';
import RestreamScheduler from './RestreamScheduler';
import RestreamPublicationProvidersSelect from './RestreamPublicationProvidersSelect';

export function RestreamCreateForm({ cameras, handleClose, abortBtn }) {
	const { client, reloadPage } = useApp();
	const [formValues, setFormValues] = React.useState({
		id: null,
		name: '', // post
		description: '', // post
		camera_id: '', // post
		camera_input_id: '', // post
		publication_provider: '', // post
		publication_token: '', // post
		publication_url: '', // post
		schedule: false,
		schedule_start: '',
		schedule_end: '',
		scheduler: '', // post
		runtime_duration_seconds: null, // post
		output_config: {}, // post
		sla: '0', // post
		enabled: true, // post
	});

	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		const newValue = type === 'checkbox' ? checked : value;
		setFormValues((prevValues) => ({
			...prevValues,
			[name]: newValue,
		}));
	};

	const enableSetup = () => {
		if (
			formValues.camera_id &&
			formValues.camera_input_id &&
			formValues.publication_provider &&
			(formValues.publication_token || formValues.publication_url) &&
			!formValues.schedule
		) {
			return true;
		} else if (
			formValues.camera_id &&
			formValues.camera_input_id &&
			formValues.publication_provider &&
			(formValues.publication_token || formValues.publication_url) &&
			formValues.schedule &&
			formValues.schedule_start
		) {
			return true;
		}
		return false;
	};

	const updateRestream = async () => {
		await client.PostCustomerRestream({
			restream: {
				name: formValues.name,
				description: formValues.description,
				camera_id: formValues.camera_id,
				camera_input_id: formValues.camera_input_id,
				publication_provider: formValues.publication_provider,
				publication_token: formValues.publication_token,
				publication_url: formValues.publication_url,
				scheduler: formValues.scheduler,
				runtime_duration_seconds: formValues.runtime_duration_seconds,
				output_config: formValues.output_config,
				enabled: formValues.enabled,
			},
		});
		reloadPage();
		handleClose();
	};

	return (
		<Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2} paddingTop={1}>
			<Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2} width={'100%'}>
				<TextField
					id="name"
					label="Name"
					name="name"
					value={formValues.name || ''}
					onChange={handleChange}
					InputLabelProps={{
						sx: {
							backgroundColor: '#fff',
							shrink: true,
						},
					}}
					fullWidth
					variant="outlined"
					size="small"
					color="secondary"
				/>
				{/* <TextField
                    id="description"
                    label="Beschreibung"
                    name="description"
                    value={formValues.description || ""}
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    variant="outlined"
                    color='secondary'
                    InputLabelProps={{
                        sx: {
                            backgroundColor: '#fff',
                            shrink: true,
                        }
                    }} 
                />*/}
				<RestreamPublicationProvidersSelect formValues={formValues} handleChange={handleChange} />
				<RestreamCameraSelect formValues={formValues} setFormValues={setFormValues} cameras={cameras} />
				<RestreamCameraProfileSelect formValues={formValues} handleChange={handleChange} cameras={cameras} />
				<RestreamScheduler formValues={formValues} setFormValues={setFormValues} handleChange={handleChange} />
			</Stack>
			<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} width={'100%'} paddingBottom={1}>
				{abortBtn && (
					<Button variant="outlined" color="primary" size="medium" onClick={handleClose}>
						Abbrechen
					</Button>
				)}
				{formValues.camera_id && formValues.publication_provider && (
					<Button variant="outlined" fullWidth color="primary" size="medium" onClick={updateRestream} disabled={!enableSetup()} autoFocus>
						{formValues.schedule ? 'Erstellen' : 'Jetzt starten'}
					</Button>
				)}
			</Stack>
		</Stack>
	);
}

RestreamCreateForm.propTypes = {
	handleClose: PropTypes.func,
	cameras: PropTypes.array,
	abortBtn: PropTypes.bool,
};

RestreamCreateForm.defaultProps = {
	handleClose: () => {},
	cameras: [],
	abortBtn: true,
};

export function RestreamCreateDialog({ cameras }) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button
				//variant="outlined"
				color="primary"
				size="small"
				sx={{
					//m: '.5em .5em 0.5em .5em',
					//padding: 0,
					height: 40,
					boxShadow: 1,
					borderRadius: 1,
					background: '#fff',
					border: '1px solid rgba(0,0,0,.2)',
					fontSize: '1em',
					padding: "0px 10px",
					color: '#0085E9',
				}}
				onClick={handleClickOpen}
			>
				<AddCircleIcon style={{ marginRight: 5, color: '#0085E9' }} /> Neu
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-edit-dialog-title"
				aria-describedby="alert-edit-dialog-description"
				PaperProps={{ style: { padding: '10px 15px 10px 15px' } }}
			>
				<DialogTitle id="alert-dialog-title" width={500}>
					Restream Einstellungen
				</DialogTitle>
				<DialogContent>
					<RestreamCreateForm cameras={cameras} handleClose={handleClose} />
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}

RestreamCreateDialog.propTypes = {
	cameras: PropTypes.array.isRequired,
};

RestreamCreateDialog.defaultProps = {
	cameras: [],
};

export default RestreamCreateDialog;
