import CryptoJS from 'crypto-js';
import PropTypes from "prop-types";


const renameKeys = (obj, newKeys) => {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    return Object.assign({}, ...keyValues);
}

const keyMap = {
    camera_id: '0',
    player_id: '1',
    user_email: '2',
    playback_user_id: '3',
    video_id: '4',
    content_type: '5',
    content_profile: '6',
    device_type: '7',
    country_iso: '8',
    region_iso: '9',
    segment1: '10',
    segment2: '11',
    segment3: '12',
    dimension1: '13',
    dimension2: '14',
    dimension3: '15',
    from_date: '16',
    to_date: '17',
    step: '18'
};
    

export function AnalyticsShare({crypt, apiQueryUncrypt, apiQueryCrypt}) {
    const secret = 'cmapi';

    if (crypt) {
        // Encrypt apiQuery
        const apiQueryUncryptCompressed = renameKeys(apiQueryUncrypt, keyMap);
        const apiQueryToCrypt = (obj) => {
            return Object.fromEntries(
                Object.entries(obj).filter(([key, value]) => value !== null)
            );
        }
        const apiQueryToCryptString = JSON.stringify(apiQueryToCrypt(apiQueryUncryptCompressed));        
        const apiQueryCiphertext = CryptoJS.RC4.encrypt(apiQueryToCryptString, secret).toString();
        let urlSafeString = encodeURIComponent(apiQueryCiphertext);
        return urlSafeString;
    } else {
        // Decrypt Shared URL to apiQuery
        try {
            let decodedString = decodeURIComponent(apiQueryCrypt);
            const apiQueryCryptBytes = CryptoJS.RC4.decrypt(decodedString, secret);
            const apiQueryCompressedString = apiQueryCryptBytes.toString(CryptoJS.enc.Utf8);
            const apiQueryCompressed = JSON.parse(apiQueryCompressedString);
            const apiQueryUncompressed = Object.keys(keyMap).reduce((acc, key) => {
                acc[keyMap[key]] = key;
                return acc;
            }, {});
            const apiQuery = renameKeys(apiQueryCompressed, apiQueryUncompressed);
            return apiQuery;
        } catch (e) {
            return null;
        }
    }
}

AnalyticsShare.propTypes = {
    crypt: PropTypes.bool.isRequired,
    apiQueryUncrypt: PropTypes.object,
    apiQueryCrypt: PropTypes.string,
};

AnalyticsShare.defaultProps = {
    crypt: true,
    apiQueryUncrypt: {},
    apiQueryCrypt: "",
};

export default AnalyticsShare;