import * as React from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

import PublicationProviderRegistry from './PublicationProviderRegistry';

import fallback from '../../images/fallback.png';


const RestreamResolution = ({ streams }) => {
	// [
	//     {
	//         "format": "hls",
	//         "codec": "h264, aac",
	//         "resolution": "1280x720",
	//         "bitrate": 530.22,
	//         "fps": 24.87
	//     }
	// ]

	if (streams.length === 0) {
		return null;
	}
	streams.sort((a, b) => a.resolution.split('x')[1] - b.resolution.split('x')[1]);

	return (
		<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5} paddingTop={0.8} paddingLeft={1.2} width={'100%'}>
			{streams.map((stream, index) => {
				if (stream.profile !== 'tv') {
					return (
						<Chip
							key={index}
							size="small"
							label={stream.resolution.split('x')[1]}
							sx={{ backgroundColor: '#0085E9', color: '#fff', fontWeight: 800, p: '.5em .1em', zIndex: 1 }}
						/>
					);
				} else {
					return (
						<Chip
							key={index}
							size="small"
							label="TV"
							sx={{ backgroundColor: '#1dad22', color: '#fff', fontWeight: 800, p: '.5em .1em', zIndex: 1 }}
						/>
					);
				}
			})}
		</Stack>
	);
};


function RestreamItem(props) {
	const { restream, restream_id, playerConfig, handleSelectRestream } = props;
	const [backgroundImage, setBackgroundImage] = React.useState(`url(${fallback})`);

	React.useEffect(() => {
		const img = new Image();
		const ts = Date.now();
		img.src = `${playerConfig.url}${restream.camera_id}/live/main_240.jpg?${ts}`;
		img.onload = () => {
			setBackgroundImage(`url(${img.src})`);
		};
	}, []);

	return (
		<Grid
			item
			key={restream.id}
			xs={12}
			sm={12}
			md={6}
			lg={4}
			xl={3}
			xxl={3}
			alignItems="flex-start"
			style={{ cursor: 'pointer' }}
			onClick={handleSelectRestream(restream)}
		>
			<Paper
				elevation={0}
				sx={{
					position: 'relative',
					backgroundImage: backgroundImage,
					backgroundSize: 'cover',
					backgroundColor: 'transparent',
					padding: 0,
					paddingTop: '56.25%',
					height: 0,
					width: 'auto',
					borderRadius: '4px 4px 0px 0px',
				}}
			>
				<Stack
					direction="column"
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					width={'100%'}
					height={'100%'}
					sx={{ 
						backgroundColor: (restream_id && restream.id === restream_id) ? "rgba(0,133,233,.5)" : "transparent",
						position: 'absolute', 
						top: 0, 
						left: 0, 
						bottom: 0, 
						right: 0,
						borderRadius: 1,
					}}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
						spacing={0}
						width={'100%'}
						marginTop={0.8} 
						marginBottom={1.4} 
						sx={{
							background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
							//background: 'linear-gradient(to bottom, rgba(193,39,45,.9), rgba(193,39,45,0))',
							// 
							width: '100%',
							borderRadius: 1,
							height: '35%',
							padding: '0.15em 0.8em',
						}}
					>
						<Stack 
							direction="column" 
							width={'100%'}
						>
							<Typography
								variant="h5"
								//marginBottom={0}
								sx={{
									fontSize: '1rem',
									fontWeight: 500,
									color: '#ffffff',
									lineHeight: '1.1rem',
									marginBottom: '0.3em',
									marginTop: '0.3em',
								}}
							>
								{restream.camera.meta.name}
							</Typography>
							<Typography 
								variant="body2" 
								color="text.secondary"
								sx={{
									color: '#ffffff',
									marginTop: '-0.4em',
									fontWeight: 400,
								}}
							>
								{restream.camera.meta.country}
							</Typography>
						</Stack>
						{!restream.status || restream.status !== 'healthy' && (
							<Stack 
							direction="column" 
							width={'auto%'}
							marginTop={.3}
						>
							<ReportProblemIcon style={{ width: 17, color: "white" }} />
						</Stack>
						)}
					</Stack>
					<Stack 
						direction="row" 
						marginTop={0.8} 
						spacing={2}
						sx={{
							background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))',
							width: '100%',
							borderRadius: 1,
							height: '25%',
							justifyContent: "space-between",
   							alignItems: "flex-end",
							paddingBottom: 1.2,
							paddingLeft: .2,
						}}
					>
						<RestreamResolution key={restream.id} streams={restream.streams} />
					</Stack>
				</Stack>
			</Paper>
            <Stack
                direction="row"
                spacing={0}
                width={'100%'}
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: "white",
                    width: '100%',
                    borderRadius: '0px 0px 4px 4px',
                    borderLeft: '1px solid rgba(0,0,0,.1)',
                    borderRight: '1px solid rgba(0,0,0,.1)',
                    borderBottom: '1px solid rgba(0,0,0,.1)',
                    height: '30px',
                    padding: '13px 10px 10px 10px',
                }}
			>
                {restream.publication_provider.icon}
                <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{
                        color: '#000',
                        marginTop: '-0.4em',
                        fontWeight: 400,
                        marginLeft: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                   {restream.name}
                </Typography>
            </Stack>
		</Grid>
	);
}


function RestreamList(props) {
	const { restreams, restream_id, page, pageSize, playerConfig, handleSelectRestream } = props;
	const paginateArray = (array, pageNumber, pageSize) => {
		const page = array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
		return page;
	};

	return (
		<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1.5} marginTop={'-7px'}>
			{paginateArray(restreams, page, pageSize).map((restream) => (
				<RestreamItem key={restream.id} restream_id={restream_id} restream={restream} playerConfig={playerConfig} handleSelectRestream={handleSelectRestream} />
			))}
		</Grid>
	);
}

RestreamList.propTypes = {
	restreams: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	playerConfig: PropTypes.object.isRequired,
	handleSelectRestream: PropTypes.func.isRequired,
};

RestreamList.defaultProps = {};


function RestreamCameraMerge({ restreams, cameras }) {
    if (!restreams || !cameras) {
        return null;
    }
    const publicationProviderRegistry = PublicationProviderRegistry({marginTop: -5});

    for (let i = 0; i < restreams.length; i++) {
        for (let j = 0; j < cameras.length; j++) {
            if (restreams[i].camera_id === cameras[j].id) {
                restreams[i].camera = cameras[j];
            }
        }
        for (let j = 0; j < publicationProviderRegistry.length; j++) {
            if (restreams[i].publication_provider === publicationProviderRegistry[j].id) {
                restreams[i].publication_provider = publicationProviderRegistry[j];
            }
        }
    }
    return restreams;
}


export default function RestreamTiles(props) {
	const { restream_id, restreams, cameras, playerConfig, navigate, rootPath } = props;

    const restream_items = RestreamCameraMerge({ restreams, cameras });

    const handleSelectRestream = React.useCallback(
        (restream) => () => {
            navigate(`${rootPath}/${restream.id}`);
        },
        [navigate],
    );

    // PAGINATION
    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
        setPage(value);
    };
    const [pageSize, setPageSize] = React.useState(50);
    const handlePageSize = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    return (
        <Stack direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2} marginTop={1.5} width={'100%'}>
            <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2} width={'100%'}>
                <Stack direction="row" justifyContent="flex-start" alignItems="stretch" spacing={0} paddingBottom={5}>
                    {restreams && (
                        <RestreamList
                            restreams={restream_items}
							restream_id={restream_id}
                            page={page}
                            pageSize={pageSize}
                            playerConfig={playerConfig}
                            handleSelectRestream={handleSelectRestream}
                        />
                    )}
                </Stack>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} marginBottom={2}>
                    <Paper
                        sx={{
                            p: '4px 8px',
                            borderRadius: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        component="form"
                    >
                        <Pagination
                            count={restream_items ? Math.ceil(restream_items.length / pageSize) : 0}
                            page={page}
                            onChange={handleChange}
                            variant="outlined"
                            shape="rounded"
                        />
                        <FormControl size="small" variant="outlined" color="primary">
                            <Select value={pageSize} onChange={handlePageSize}>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={250}>250</MenuItem>
                            </Select>
                        </FormControl>
                    </Paper>
                </Stack>
            </Stack>
        </Stack>
    );
}

RestreamTiles.propTypes = {
    restreams: PropTypes.array,
    cameras: PropTypes.array,
    playerConfig: PropTypes.object,
};