import * as React from "react";
import PropTypes from "prop-types";

import "moment/locale/de";
import "moment-timezone";
import moment from "moment";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AlertBox from "./AlertBox";
// import LatestCamBox from "./LatestCamBox";
import LiveBox from "./LiveBox";
import LiveViewerBox from "./LiveViewerBox";
import HistorySummaryBox from "./HistorySummaryBox";
import Page from "../../components/Page";

import { useApp } from "../../context/App";
import useInterval from "../../hooks/useInterval";

import packageJson from '../../../package.json';

function Dashboard() {
    const { client, backdrop, reload, setSelectedPage } = useApp();
    const [ready, setReady] = React.useState(false);

    // PAGE SIDEBAR
    const [openSidebar, setOpenSidebar] = React.useState(false);
    const handleSidebarToggle = () => {
        setOpenSidebar(!openSidebar);
    };

    // PLAYER OBJECT
    const [playerConfig, setPlayerConfig] = React.useState(false);
    const getPlayerConfig = async () => {
        const data = client.PlayerConfig();
        setPlayerConfig(data);
        return playerConfig;
    };

    // SET PAGE
    React.useEffect(() => {
        setSelectedPage("dashboard");
    }, [setSelectedPage]);

    // METRICS FUNCTIONS
    function sortTs(data, fromDate, toDate, step) {
        const filledData = [];
        let currentTime = new Date(fromDate.toISOString()).getTime() / 1000;
    
        while (currentTime <= new Date(toDate.toISOString()).getTime() / 1000) {
            filledData.push([currentTime, "0"]);
            currentTime += step;
        }
    
        data.forEach(entry => {
            let closestIndex = -1;
            let closestDistance = Infinity;
    
            filledData.forEach((item, index) => {
                const distance = Math.abs(item[0] - entry[0]);
                if (distance < closestDistance) {
                    closestDistance = distance;
                    closestIndex = index;
                }
            });
    
            if (closestIndex !== -1 && filledData[closestIndex][1] === "0") {
                filledData[closestIndex][1] = entry[1].toString();
            }
        });
    
        return filledData;
    }

    function createSummary(metrics) {
        metrics.sort((a, b) => a[0] - b[0]);

        const summary = [];
        for (const m in metrics) {
            summary.push(metrics[m][1]);
        }
        const current = Number(summary[summary.length - 1]);        
        const max = parseFloat(Math.max(...summary).toFixed(2));
        const min = parseFloat(Math.min(...summary).toFixed(2));
        
        const n = summary.length;
        let sumX = 0, sumY = 0, sumXY = 0, sumXX = 0;

        for (let i = 0; i < n; i++) {
            const x = i;
            const y = parseFloat(summary[i]);
            sumX += x;
            sumY += y;
            sumXY += x * y;
            sumXX += x * x;
        }

        const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
        const trend = parseFloat(slope.toFixed(2));

        return { 
            current: current,
            max: max,
            min: min,
            trend: trend,
        };
    }

    // SET AND UPDATE CAMERAS
    const [cameras, setCameras] = React.useState(null);

    async function fetchCamerasData() {
        try {
            const data = await client.GetCustomerCamera();
            setCameras(data);
            return data;
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchCamerasStatus(camerasData) {
        try {
            await client.GetCustomerCamerasStats({
                metric: ['healthy'],
            }).then((data) => updateCamerasStatus(camerasData, data));
        } catch (error) {
            console.log(error);
        }
    }

    const updateCamerasStatus = async (cameraData, stats) => {
        try {
            for (const camera of cameraData) {
                const cameraStats = stats.find((stat) => stat.camera_id === camera.id);
                if (cameraStats) {
                    camera.status = cameraStats.status === "1" ? 'healthy' : 'unhealthy';
                } else {
                    camera.status = 'unknown';
                }
            }
            setCameras(cameraData);
        } catch (error) {
            console.log(error);
        }
    }

    // SET AND UPDATE CAMERA METRICS
    const [cameraMetrics, setCameraMetrics] = React.useState(null);

    async function fetchCameraMetric(camerasData, from_date, to_date, step) {
        try {
            await client.PostCustomerAnalyticsLive({
                group_by: ["camera"],
                step: step.toString(),
                from_date: from_date.format("YYYY-MM-DDTHH:mm:ss"),
                to_date: to_date.format("YYYY-MM-DDTHH:mm:ss"),
            }).then((data) => updateCameraMetrics(camerasData, data, from_date, to_date, step));
            setReady(true);
        } catch (error) {
            console.log(error);
        }
    }

    const updateCameraMetrics = async (cameraData, metricData, from_date, to_date, step) => {
        try {
            for (const m in metricData) {
                for (const c in cameraData) {
                    if (cameraData[c].id === metricData[m].metric.camera_id) {
                        metricData[m].metric.camera = cameraData[c];
                        metricData[m].values = sortTs(metricData[m].values, from_date, to_date, step);
                        metricData[m].summary = createSummary(metricData[m].values);
                    }
                }
            }
            setCameraMetrics(metricData);
        } catch (error) {
            console.log(error);
        }
    }

    // SET AND UPDATE PLAYER
    const [player, setPlayer] = React.useState(null);

    async function fetchPlayerData() {
        try {
            const data = await client.GetCustomerPlayer({ acl_id: null });
            setPlayer(data);
            return data;
        } catch (error) {
            console.log(error);
        }
    }

    async function fetchPlayerMetric(playerData, from_date, to_date, step) {
        try {
            await client.PostCustomerAnalyticsLive({
                group_by: ["player"],
                step: step.toString(),
                from_date: from_date.format("YYYY-MM-DDTHH:mm:ss"),
                to_date: to_date.format("YYYY-MM-DDTHH:mm:ss"),
            }).then((data) => updatePlayerMetrics(playerData, data, from_date, to_date, step));
            setReady(true);
        } catch (error) {
            console.log(error);
        }
    }

    // SET AND UPDATE PLAYER METRICS
    const [playerMetrics, setPlayerMetrics] = React.useState(null);
    
    const updatePlayerMetrics = async (playerData, metricData, from_date, to_date, step) => {
        try {
            for (const m in metricData) {
                for (const p in playerData) {
                    if (playerData[p].id === metricData[m].metric.player_id) {
                        metricData[m].metric.player = playerData[p];
                        metricData[m].values = sortTs(metricData[m].values, from_date, to_date, step);
                        metricData[m].summary = createSummary(metricData[m].values);
                    }
                }
            }
            setPlayerMetrics(metricData);
        } catch (error) {
            console.log(error);
        }
    }

    // SET AND UPDATE ALERTS
    const [alerts, setAlerts] = React.useState(null);

    async function fetchAlerts(camerasData) {
        try {
            await client.GetCustomerAlert()
                .then((data) => updateAlerts(camerasData, data));
        } catch (error) {
            console.log(error);
        }
    }

    const updateAlerts = async (cameraData, alertData) => {
        try {
            for (const a in alertData) {
                for (const c in cameraData) {
                    if (alertData[a].item_id === cameraData[c].id) {
                        alertData[a].camera = cameraData[c];
                    }
                }
            }
            setAlerts(alertData);
        } catch (error) {
            console.log(error);
        }
    }

    // SET AND UPDATE REPORT
    const [report, setReport] = React.useState(null);
    const reportDateRange = {
        from_date: moment().hours(0).minutes(0).seconds(0).subtract(29, "days"),
        to_date: moment().hours(0).minutes(0).seconds(0).subtract(1, "days"),
    };

    async function fetchReport() {
        try {
            await client.PostCustomerAnalyticsReport({
                from_date: reportDateRange.from_date.format("YYYY-MM-DDTHH:mm:ss"),
                to_date: reportDateRange.to_date.format("YYYY-MM-DDTHH:mm:ss"),
            }).then((data) => setReport(data));
        } catch (error) {
            console.log(error);
        }
    }

    // FETCH INITIAL DATA (ASYNC)
    React.useEffect(() => {
        (async () => {
            setOpenSidebar(false);
            await getPlayerConfig();

            const camerasData = await fetchCamerasData();
            const playerData = await fetchPlayerData();

            // METRICS SETTINGS
            const from_date = moment().subtract(6, "hours").seconds(0).utc();
            const to_date = moment().seconds(0).utc();
            const step = 720

            if (camerasData) {
                fetchAlerts(camerasData); // asnyc
                fetchCameraMetric(camerasData, from_date, to_date, step); // asnyc
            }

            if (playerData) {
                fetchPlayerMetric(playerData, from_date, to_date, step); // asnyc
            }

            fetchReport(); // asnyc

            return () => {
                setReady(false);
            };

        })();
    // eslint-disable-next-line
    }, [client, reload]);

    useInterval(async () => {

        // METRICS SETTINGS
        const from_date = moment().subtract(6, "hours").seconds(0).utc();
        const to_date = moment().seconds(0).utc();
        const step = 720

        fetchCamerasStatus(cameras); // asnyc
        fetchAlerts(cameras); // asnyc
        fetchCameraMetric(cameras, from_date, to_date, step); // asnyc
        fetchPlayerMetric(player, from_date, to_date, step); // asnyc

        return;
    }, 60000);

    React.useEffect(() => {
        backdrop(!ready);
    }, [ready, backdrop]);

    return (
        <Page
            drawerWidth={550}
            openSidebar={openSidebar}
            handleSidebarToggle={handleSidebarToggle}
            headerContentLeft={
                <Typography variant="h6" component="h1" noWrap sx={{ color: "#0084E8", fontWeight: 500, marginTop: 1.9, marginRight: 2 }}>v{packageJson.version}</Typography>
            }
            pageTitel="Übersicht"
            breadcrumbs={[]}
        //sidebarContent={}
        >
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
                marginLeft={"-25px"}
                marginRight={"-25px"}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12} md={5} lg={3} xl={2}>
                        <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Paper
                                elevation={1}
                                sx={{
                                    width: "100%",
                                    border: '1px solid rgba(0,0,0,.1)',
                                    backgroundColor: '#0084E8',
                                }}
                            >
                                <LiveViewerBox playerMetrics={playerMetrics} />
                            </Paper>
                            <Paper
                                elevation={1}
                                sx={{
                                    width: "100%",
                                    border: '1px solid rgba(0,0,0,.1)',
                                    backgroundColor: 'rgb(255,255,255,1)',
                                }}
                            >
                                <HistorySummaryBox report={report} reportDateRange={reportDateRange} />
                            </Paper>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={7} lg={4} xl={4.5}>
                        <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            {/* {cameraMetrics && (
                                <Paper
                                    elevation={1}
                                    sx={{
                                        width: "100%",
                                        border: '1px solid rgba(0,0,0,.1)',
                                        backgroundColor: 'rgb(255,255,255,1)',
                                        padding: 0,
                                    }}
                                >
                                    <LatestCamBox key={cameraMetrics} playerConfig={playerConfig} cameraMetrics={cameraMetrics} />
                                </Paper>
                            )} */}
                            <Paper
                                elevation={1}
                                sx={{
                                    width: "100%",
                                    border: '1px solid rgba(0,0,0,.1)',
                                    backgroundColor: 'rgb(255,255,255,1)',
                                }}
                            >
                                <LiveBox
                                    cameraMetrics={cameraMetrics}
                                    playerMetrics={playerMetrics}
                                    playerConfig={playerConfig}
                                />
                            </Paper>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} lg={5} xl={5.5}>
                        <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Paper
                                elevation={1}
                                sx={{
                                    width: "100%",
                                    border: '1px solid rgba(0,0,0,.1)',
                                    backgroundColor: 'rgb(255,255,255,1)',
                                }}
                            >
                                <AlertBox alerts={alerts} playerConfig={playerConfig} />
                            </Paper>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Page>
    );
}

Dashboard.propTypes = {
    openMenu: PropTypes.bool,
    handleMenuToggle: PropTypes.func,
    setSelectedPage: PropTypes.func,
};

export default Dashboard;
