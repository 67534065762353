import * as React from "react";

import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CustomerForm from "./CustomerForm";

function EditDialog(props) {
    const [editCustomer, setEditCustomer] = React.useState(props.customer);
    const [formValuesChanged, setFormValuesChanged] = React.useState(false);

    const handleChange = (customer) => {
        setEditCustomer(customer);
        setFormValuesChanged(true);
    };

    const handleSave = async () => {
        props.onChange(editCustomer);
    };

    const handleReset = () => {
        setFormValuesChanged(false);
        setEditCustomer({
            ...props.customer,
        });
    };

    const handleDelete = async () => {
        props.onDelete();
    };

    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle fontSize={22}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <span>KUNDEN EINSTELLUNG</span>
                        <IconButton
                            aria-label="open-edit"
                            onClick={props.onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <CustomerForm
                        mode="edit"
                        customer={editCustomer}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        width={"100%"}
                    >
                        <Button
                            variant="standard"
                            color="danger"
                            onClick={handleDelete}
                        >
                            Löschen ...
                        </Button>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            spacing={1}
                        >
                            <Button
                                disabled={!formValuesChanged}
                                variant="outlined"
                                onClick={handleReset}
                            >
                                Zurücksetzen
                            </Button>
                            <Button
                                disabled={!formValuesChanged}
                                variant="outlined"
                                onClick={handleSave}
                            >
                                Speichern
                            </Button>
                        </Stack>
                    </Stack>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

EditDialog.propTypes = {
    customer: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

EditDialog.defaultProps = {
    customer: {},
    open: false,
    onClose: () => {},
    onChange: () => {},
};

export default EditDialog;
