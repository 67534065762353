import * as React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";

import ACLSelect from "../../../../components/ACLSelect";

function PlayerGoogleAnalytics({ player }) {
    let analytics = {
        ga_account: "",
        ga_name: "",
    };

    if (player.analytics) {
        analytics = player.analytics;
    }

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <TextField
                    label="Account"
                    value={analytics.ga_account ? analytics.ga_account : ""}
                    disabled={!analytics.ga_account}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Name"
                    value={analytics.ga_name ? analytics.ga_name : ""}
                    disabled={!analytics.ga_name}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
        </React.Fragment>
    );
}

PlayerGoogleAnalytics.propTypes = {
    player: PropTypes.object.isRequired,
};

PlayerGoogleAnalytics.defaultProps = {};

function PlayerColor({ player }) {
    let color = {
        seekbar: "#fff",
        buttons: "#fff",
    };

    if (player.color) {
        color = player.color;
    }

    return (
        <React.Fragment>
            <Grid item xs={6}>
                <TextField
                    label="Farbe der Leiste"
                    value={color.seekbar ? color.seekbar : "#fff"}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Farbe der Knöpfe"
                    value={color.buttons ? color.buttons : "#fff"}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
        </React.Fragment>
    );
}

PlayerColor.propTypes = {
    player: PropTypes.object.isRequired,
};

PlayerColor.defaultProps = {};

function PlayerLogo({ player }) {
    let logo = {
        image: "",
        position: "top-right",
        link: "",
    };

    if (player.logo) {
        logo = player.logo;
    }

    return (
        <React.Fragment>
            <Grid item xs={12}>
                <TextField
                    label="Logo"
                    value={logo.image ? logo.image : ""}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    label="Position"
                    value={logo.position ? logo.position : "top-right"}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
            <Grid item xs={8}>
                <TextField
                    label="Link"
                    value={logo.link ? logo.link : ""}
                    size="small"
                    readOnly
                    variant="standard"
                    fullWidth
                />
            </Grid>
        </React.Fragment>
    );
}

PlayerLogo.propTypes = {
    player: PropTypes.object.isRequired,
};

PlayerLogo.defaultProps = {};

function PlaybackPlayerSidebarSettings({ player }) {
    const [logo, setLogo] = React.useState(false);
    const [googleAnalytics, setGoogleAnalytics] = React.useState(false);
    const [acl, setACL] = React.useState(player.acl);

    // eslint-disable-next-line
    React.useEffect(() => {
        if (player.logo) {
            setLogo(true);
        } else {
            setLogo(false);
        }
        if (player.analytics && player.analytics.ga_account && player.ga_name) {
            setGoogleAnalytics(true);
        } else {
            setGoogleAnalytics(false);
        }
    });

    return (
        <Stack
            padding="5px 20px 20px 20px"
            spacing={0}
        >
            <Grid
                container
                justifyContent="space-between"
                fontSize={14}
                style={{
                    marginTop: 6,
                }}
                spacing={2}
            >
                <Grid item xs={12}>
                    <TextField
                        label="Name"
                        value={player.name}
                        disabled={!player.name}
                        size="small"
                        readOnly
                        variant="standard"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Typography marginTop={2.5} marginBottom={1.5} variant="h4">
                Sicherheit
            </Typography>
            <Stack
                style={{
                    padding: "15px 15px 15px 15px",
                    marginBottom: 5,
                    backgroundColor: "rgba(0,0,0,.035)",
                    borderRadius: 4,
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <ACLSelect acl={acl} setACL={setACL} readOnly />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="domain_whitelist"
                            value={player.domain_whitelist}
                            disabled={!player.domain_whitelist}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Maximale Wiedergabezeit"
                            value={player.token_expires_hours}
                            disabled={!player.token_expires_hours}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={player.enabled} />}
                                label="Verfügbar"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Stack>
            <Typography marginTop={2.5} marginBottom={1.5} variant="h4">
                Layout
            </Typography>
            <Stack
                style={{
                    padding: "15px 15px 15px 15px",
                    marginBottom: 5,
                    backgroundColor: "rgba(0,0,0,.035)",
                    borderRadius: 4,
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <TextField
                            label="Template"
                            value={player.theme}
                            disabled={!player.theme}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                    <PlayerColor player={player} />
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={player.logo} />}
                                label="Logo"
                            />
                        </FormGroup>
                    </Grid>
                    {logo && <PlayerLogo player={player} />}
                    {/* <Grid item xs={12}>
                        <TextField
                            label="poster_url"
                            value={player.poster_url}
                            disabled={!player.poster_url}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={player.camera_poster} />} label="Kamera Poster" />
                        </FormGroup>
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField
                            label="Video Format"
                            value={player.aspect_ratio}
                            disabled={!player.aspect_ratio}
                            size="small"
                            readOnly
                            variant="standard"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Stack>
            <Typography marginTop={2.5} marginBottom={1.5} variant="h4">
                Funktionen
            </Typography>
            <Stack
                style={{
                    padding: "10px 15px 10px 15px",
                    marginBottom: 5,
                    backgroundColor: "rgba(0,0,0,.035)",
                    borderRadius: 4,
                }}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={player.camera_poster} />
                                }
                                label="Kamera Poster"
                            />
                        </FormGroup>
                        <FormHelperText>
                            Alternativ zum Vorschaubild wird das statische
                            Poster der Kamera angezeigt, sofern es vorhanden
                            ist.
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={player.autoplay} />}
                                label="Automatische Wiedergabe"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={player.mute} />}
                                label="Audio stummschalten"
                            />
                        </FormGroup>
                    </Grid>

                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={player.airplay} />}
                                label="Apple Airplay"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={player.chromecast} />
                                }
                                label="Google Chromecast"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={googleAnalytics} />}
                                label="Google Analytics"
                            />
                        </FormGroup>
                    </Grid>
                    {googleAnalytics && (
                        <PlayerGoogleAnalytics player={player} />
                    )}
                </Grid>
            </Stack>
        </Stack>
    );
}

PlaybackPlayerSidebarSettings.propTypes = {
    player: PropTypes.object.isRequired,
};

PlaybackPlayerSidebarSettings.defaultProps = {};

export default PlaybackPlayerSidebarSettings;
