import * as React from "react";
import PropTypes from "prop-types";

import "moment-timezone";
import moment from "moment";

import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Moment from "react-moment";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import SidebarTabPanel from "../../../../components/SidebarTabPanel";
import SidebarTabPanelProps from "../../../../components/SidebarTabPanelProps";

import AdminUserSidebarMenu from "./AdminUserSidebarMenu";

import { useApp } from "../../../../context/App";


function Role({ name }) {
    return (
        <Chip
            size="small"
            Typographyvariant="outlined"
            style={{ textTransform: "initial", fontWeight: 500, margin: 3, maxWidth: 480 }}
            label={`${name}`}
        />
    );
}
Role.propTypes = {
    name: PropTypes.string,
};
Role.defaultProps = {};


function AdminUserSidebar({ drawerWidth, user, adminRoles }) {
    const { client, hasPermission } = useApp();
    // SIDEBAR TABPANEL
    const [tab, setTab] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const [userDetail, setUserDetail] = React.useState(null);

    React.useEffect(() => {
        (async () => {
            if (!userDetail) {
                client
                    .GetCustomerUserId({
                        username: user.username,
                    })
                    .then((data) => setUserDetail(data));
            }
        })();
        // eslint-disable-next-line
    }, []);

    // TEXTFIELD NEEDS STR
    for (const k in user) {
        if (user[k] === null && k !== "acl_id") {
            user[k] = "";
        }
    }

    const [checkedEmailOptIn, setCheckedEmailOptIn] = React.useState(
        user.email_opt_in,
    );
    const handleChangeEmailOptIn = (event) => {
        setCheckedEmailOptIn(event.target.checked);
    };
    const acl = user.acl;

    return (
        <Stack width={drawerWidth}>
            <DialogTitle
                id="scroll-dialog-title"
                style={{
                    padding: "10px 0px 0px 0px",
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    fontSize={22}
                    fontWeight={500}
                    lineHeight={"normal"}
                    padding={"0px 20px 20px 20px"}
                >
                    {user.first_name} {user.last_name}
                </Stack>
            </DialogTitle>
            <Divider sx={{width: "100%", marginTop: "0px", marginBottom: "-2px", border: "2px solid rgba(0,0,0,.1)", borderTop: "1px solid rgba(0,0,0,.2)"}} />
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                marginTop={0}
                borderBottom={"1px solid rgba(0, 0, 0, 0.12)"}
                maxWidth={drawerWidth}
            >
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons={false}
                >
                    <Tab label="Einstellung" {...SidebarTabPanelProps(0)} />
                    <Tab
                        label="Sitzungen"
                        {...SidebarTabPanelProps(1)}
                        disabled={!hasPermission(adminRoles)}
                    />
                    {/* <Tab label="Protokoll" {...SidebarTabPanelProps(2)} /> */}
                </Tabs>
                <AdminUserSidebarMenu
                    user={user}
                    adminRoles={adminRoles}
                />
            </Stack>
            <SidebarTabPanel value={tab} index={0} padding={0}>
                <Stack
                    padding="5px 20px 0px 20px"
                    spacing={0}
                >
                    <Stack
                        style={{
                            padding: "10px 15px 10px 15px",
                            marginBottom: 5,
                            backgroundColor: "rgba(0,0,0,.035)",
                            borderRadius: 4,
                        }}
                        spacing={0.5}
                    >
                        <Typography fontSize={14}>
                            <strong>Typ:</strong> {user.type === "user" ? "Mensch" : "Maschine"}
                        </Typography>
                        <Typography fontSize={14}>
                            <strong>Erstellt:</strong>{" "}
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(user.created_at).tz(
                                    moment.tz.guess(),
                                )}
                            </Moment>
                        </Typography>
                        {user.updated_at && (
                            <Typography fontSize={14}>
                                <strong>Aktualisiert:</strong>{" "}
                                <Moment
                                    format="DD.MM.YYYY HH:mm:ss"
                                    locale="de"
                                >
                                    {moment(user.updated_at).tz(
                                        moment.tz.guess(),
                                    )}
                                </Moment>
                            </Typography>
                        )}
                    </Stack>
                    <Grid
                        container
                        justifyContent="space-between"
                        fontSize={14}
                        style={{
                            marginTop: 6,
                            marginLeft: -5,
                        }}
                        spacing={1}
                    >
                        {user.type === "user" && (
                            <Grid item xs={4}>
                                <Paper
                                    elevation={2}
                                    sx={{
                                        borderRadius: 2,
                                        padding: 1,
                                        p: "0px 0px",
                                        display: "flex",
                                        alignItems: "center",
                                        backgroundColor: "#fff",
                                        marginBottom: 2,
                                        marginRight: 1,
                                    }}
                                >
                                    <Avatar
                                        variant="rounded"
                                        alt="Remy Sharp"
                                        src={user.avatar_url}
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            borderRadius: 2,
                                        }}
                                    ></Avatar>
                                </Paper>
                            </Grid>
                        )}
                        <Grid item xs={user.type === "user" ? 8 : 12}>
                            <TextField
                                label="Beschreibung"
                                value={user.description}
                                size="small"
                                multiline
                                readOnly
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        {user.username !== user.email && (
                            <Grid item xs={12}>
                                <TextField
                                    label="Anmeldename"
                                    value={user.username}
                                    size="small"
                                    readOnly
                                    variant="standard"
                                    fullWidth
                                />
                            </Grid>
                        )}
                        <Grid item xs={user.type === "user" ? 9 : 12}>
                            <TextField
                                label="E-Mail"
                                value={user.email}
                                size="small"
                                readOnly
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        {user.type === "user" && (
                            <Grid item xs={3}>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-end"
                                    height={"100%"}
                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            label="Opt-In"
                                            control={
                                                <Checkbox
                                                    checked={checkedEmailOptIn}
                                                    onChange={
                                                        handleChangeEmailOptIn
                                                    }
                                                    disabled
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                        )}
                        {user.type === "user" && (
                            <React.Fragment>
                                <Grid item xs={9}>
                                    <TextField
                                        label="Telefon"
                                        value={user.phone}
                                        size="small"
                                        readOnly
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Mobil"
                                        value={user.mobile ? user.mobile : ""}
                                        size="small"
                                        readOnly
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                            </React.Fragment>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                label="Zugriffssteuerungslisten (ACL)"
                                value={acl ? acl.name : ""}
                                size="small"
                                readOnly
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} marginBottom={-.5}>
                            <Typography fontSize={12}>Zugriffsrechte:</Typography>
                        </Grid>
                        <Grid item xs={12} fontWeight={600}>
                            {user.permission_roles && 
                                user.permission_roles.map((r) => (
                                    <Role key={r} name={r} />
                                ))
                            }
                        </Grid>
                    </Grid>
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={1} padding={0}>
                <Stack
                    padding="5px 20px 0px 20px"
                    spacing={0}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        fontSize={14}
                        spacing={1}
                    >
                        <Grid item xs={12} fontWeight={600}>
                            Letzter Zugriff:
                        </Grid>
                        {userDetail && userDetail.session_last && (
                            <Grid item xs={12}>
                                <Stack
                                    direction="column"
                                    style={{
                                        padding: "10px 15px 10px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={0}
                                >
                                    <Typography fontSize={14} fontWeight={600}>
                                        Standort
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        marginBottom={0.5}
                                    >
                                        {userDetail.session_last.location}
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={600}>
                                        IP-Adresse
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        marginBottom={0.5}
                                    >
                                        {userDetail.session_last.ip}
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={600}>
                                        Browser Agent
                                    </Typography>
                                    <Typography fontSize={14}>
                                        {userDetail.session_last.agent}
                                    </Typography>
                                    {userDetail.session_last.created_at && userDetail.session_last.expires_at && (
                                        <React.Fragment>
                                            <Typography fontSize={14} fontWeight={600}>
                                                Erstellt:
                                            </Typography>
                                            <Typography fontSize={14}>
                                                <Moment
                                                    format="DD.MM.YYYY HH:mm:ss"
                                                    locale="de"
                                                >
                                                    {moment(userDetail.session_last.created_at).tz(
                                                        moment.tz.guess(),
                                                    )}
                                                </Moment>
                                            </Typography>
                                            <Typography fontSize={14} fontWeight={600}>
                                                Gültig bis:
                                            </Typography>
                                            <Typography fontSize={14}>
                                                <Moment
                                                    format="DD.MM.YYYY HH:mm:ss"
                                                    locale="de"
                                                >
                                                    {moment(userDetail.session_last.expires_at).tz(
                                                        moment.tz.guess(),
                                                    )}
                                                </Moment>
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </Stack>
                            </Grid>
                        )}
                        {userDetail && !userDetail.session_last && (
                            <Grid item xs={12}>
                                <Stack
                                    direction="column"
                                    style={{
                                        padding: "10px 15px 10px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={0}
                                >
                                    <Typography fontSize={14}>
                                        Noch nie eingeloggt.
                                    </Typography>
                                </Stack>
                            </Grid>
                        )}
                        <Grid item xs={12} fontWeight={600}>
                            Aktiver Zugriff:
                        </Grid>
                        {userDetail && userDetail.session_active && (
                            <Grid item xs={12}>
                                <Stack
                                    direction="column"
                                    style={{
                                        padding: "10px 15px 10px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={0}
                                >
                                    <Typography fontSize={14} fontWeight={600}>
                                        Standort
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        marginBottom={0.5}
                                    >
                                        {userDetail.session_active.location}
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={600}>
                                        IP-Adresse
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        marginBottom={0.5}
                                    >
                                        {userDetail.session_active.ip}
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={600}>
                                        Browser Agent
                                    </Typography>
                                    <Typography fontSize={14}>
                                        {userDetail.session_active.agent}
                                    </Typography>
                                    {userDetail.session_active.created_at && userDetail.session_active.expires_at && (
                                        <React.Fragment>
                                            <Typography fontSize={14} fontWeight={600}>
                                                Erstellt:
                                            </Typography>
                                            <Typography fontSize={14}>
                                                <Moment
                                                    format="DD.MM.YYYY HH:mm:ss"
                                                    locale="de"
                                                >
                                                    {moment(userDetail.session_active.created_at).tz(
                                                        moment.tz.guess(),
                                                    )}
                                                </Moment>
                                            </Typography>
                                            <Typography fontSize={14} fontWeight={600}>
                                                Gültig bis:
                                            </Typography>
                                            <Typography fontSize={14}>
                                                <Moment
                                                    format="DD.MM.YYYY HH:mm:ss"
                                                    locale="de"
                                                >
                                                    {moment(userDetail.session_active.expires_at).tz(
                                                        moment.tz.guess(),
                                                    )}
                                                </Moment>
                                            </Typography>
                                        </React.Fragment>
                                    )}
                                </Stack>
                            </Grid>
                        )}
                        {userDetail && !userDetail.session_active && (
                            <Grid item xs={12}>
                                <Stack
                                    direction="column"
                                    style={{
                                        padding: "10px 15px 10px 15px",
                                        marginBottom: 5,
                                        backgroundColor: "rgba(0,0,0,.035)",
                                        borderRadius: 4,
                                    }}
                                    spacing={0}
                                >
                                    <Typography fontSize={14}>
                                        Schon länger nicht gesehen.
                                    </Typography>
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Stack>
            </SidebarTabPanel>
            <SidebarTabPanel value={tab} index={2} padding={0}>
                <Stack
                    style={{
                        padding: "0px 25px 20px 25px",
                        marginLeft: -25,
                        marginRight: -25,
                        marginBottom: 5,
                    }}
                    spacing={2}
                >
                    <Grid
                        container
                        justifyContent="space-between"
                        fontSize={14}
                        style={{
                            marginTop: 6,
                        }}
                        spacing={1}
                    >
                        <Grid item xs={3} fontWeight={600}>
                            Erstellt:
                        </Grid>
                        <Grid item xs={9}>
                            <Moment format="DD.MM.YYYY HH:mm:ss" locale="de">
                                {moment(user.created_at).tz(moment.tz.guess())}
                            </Moment>
                        </Grid>
                        {user.updated_at && (
                            <React.Fragment>
                                <Grid item xs={3} fontWeight={600}>
                                    Aktualisiert:
                                </Grid>
                                <Grid item xs={9}>
                                    <Moment
                                        format="DD.MM.YYYY HH:mm:ss"
                                        locale="de"
                                    >
                                        {moment(user.updated_at).tz(
                                            moment.tz.guess(),
                                        )}
                                    </Moment>
                                </Grid>
                            </React.Fragment>
                        )}
                        <Grid item xs={12} fontWeight={600}>
                            Opt-in:
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </Stack>
            </SidebarTabPanel>
        </Stack>
    );
}

AdminUserSidebar.propTypes = {
    drawerWidth: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    adminRoles: PropTypes.array,
};

AdminUserSidebar.defaultProps = {
    adminRoles: [],
};

export default AdminUserSidebar;
