import React, { useEffect, useRef } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from "!video.js";
import "video.js/dist/video-js.css";
import "./VideoJSSkin.css";
import "./VideoJSSkin2.css";

const VideoPlayer = (props) => {
  const videoNode = useRef(null);
  const player = useRef(null);

  useEffect(() => {
    if (videoNode.current && !player.current) {
      player.current = videojs(videoNode.current, props, () => {});
    }
    return () => {
      if (player.current) {
        player.current.dispose();
        player.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!player.current) return;

    if (props.sources) {
      player.current.src(props.sources);
    }

    if (props.poster) {
      player.current.poster(props.poster);
    }

    if (props.autoplay) {
      player.current.autoplay(props.autoplay);
    }

    if (props.skinName) {
      player.current.removeClass(props.skinName || "vjs-public");
      player.current.addClass(props.skinName || "vjs-public");
    } else {
      player.current.addClass("vjs-public");
    }
  }, [props.sources, props.skinName, props.poster, props.muted]);

  return (
    <div data-vjs-player>
      <video
        ref={videoNode}
        className={`video-js ${props.skinName || "vjs-public"}`}
      />
    </div>
  );
};

export default VideoPlayer;
